import { Box, alpha, Stack, Divider, styled, useTheme } from "@mui/material";

import NavLinks from "../../Navigation/NavLinks";

import HeaderUserbox from "./Userbox";
import HeaderMenu from "./Menu";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: 65px;
        color: white;
        padding: 15px;
        right: 0;
        z-index: 6;
        background-color: #036;
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: 1280px) {
            left: 0px;
            width: auto;
        }
`
);

function Header({ onLoginChange }) {
  const theme = useTheme();

  return (
    <HeaderWrapper display="flex">
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={0}
      >
        <HeaderMenu />

        {/* <a href="/model" style={{ textDecoration: "none" }}>
          <h4 style={{ color: "white" }}>Model Inventory</h4>
        </a> */}
      </Stack>
      <Box display="flex" alignItems="center">
        {/*<HeaderButtons />*/}
        <NavLinks />
        <HeaderUserbox onLoginChange={onLoginChange} />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: "none", xs: "inline-block" },
          }}
        ></Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
