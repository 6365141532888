import { useState, useEffect, Fragment, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import _ from "lodash";

import { toast } from "react-toastify";
import { Popconfirm } from "antd";

import Toast from "../../components/Utils/Toast";
import {
  saveDataInIndexDB,
  getDataFromDB,
  deleteDataFromIndexDB,
  clearIndexDB,
  db,
} from "./IndexedDBTemplate";

import InputNew from "../../components/Content/FormElements/InputNew";
import FileLoad from "../../components/CustomComponents/FileLoad";
import ListTab from "../../components/CustomComponents/ListTab";

import {
  VALIDATOR_CODE,
  VALIDATOR_VALIDATION_ID,
  VALIDATOR_MODEL_VERSION_ID,
  VALIDATOR_VALIDATION_ACTIVITY_ID,
} from "../../components/Utils/Validators";

import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Pagination,
  Dialog,
  DialogActions,
  DialogTitle,
  PaginationItem,
} from "@mui/material";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { UserContext } from "../../context/user-context";
import PopUpTable from "./PopUpTable";
import OpmScore from "../../pages/OpmPage/OpmScore/OpmScore";
import { MetricCheck } from "../../pages/OpmPage/NewOpmPage/NewOpm";
import { isAttendanceIncludesNonNumber } from "../../pages/MacAttendancePage/MacAttendance";

export function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length;
}

function removeHtmlTags(str) {
  if (typeof str !== "string") return str;
  const tempElement = document.createElement("div");
  tempElement.innerHTML = str;
  return tempElement.textContent || tempElement.innerText || "";
}

function removeEscapeCharacters(str) {
  if (typeof str !== "string") return "";
  // Regular expression to match all escape characters
  const regex = /[\r\n\t\\\'\"\?\f\b\s\s+]+/g;

  // Replace escape characters with an empty string
  return str.replace(regex, " ");
}

export function isTwoJsonValueEqual(a, b, form) {
  if (_.isEqual(a, b)) {
    return true;
  } else {
    if (_.isEmpty(a) && _.isEmpty(b)) {
      return true;
    }

    if (_.isEqual([a], b) || _.isEqual(a, [b])) {
      return true;
    }

    if (form === "validation" && Array.isArray(a) && Array.isArray(b)) {
      if (
        _.isEqual(
          a.map((item) => [
            item.assumption_status,
            item.condition,
            item.identifier,
            item.monitoring_frequency,
          ]),
          b.map((item) => [
            item.assumption_status,
            item.condition,
            item.identifier,
            item.monitoring_frequency,
          ])
        )
      ) {
        return true;
      }
    }
    if (form === "quantitative" && Array.isArray(a) && Array.isArray(b)) {
      if (
        _.isEqual(
          a.map((item) =>
            [item.value1, item.value2, item.value3, item.value4].map((i) =>
              _.isNil(i) ? "" : i
            )
          ),
          b.map((item) =>
            [item.value1, item.value2, item.value3, item.value4].map((i) =>
              _.isNil(i) ? "" : i
            )
          )
        )
      ) {
        return true;
      }
    }
    if (form === "qualitative" && Array.isArray(a) && Array.isArray(b)) {
      if (
        _.isEqual(
          a.map((item) =>
            [
              item.attestation1,
              item.attestation2,
              item.attestation3,
              item.attestation4,
            ].map((i) => (_.isNil(i) ? "" : i))
          ),
          b.map((item) =>
            [
              item.attestation1,
              item.attestation2,
              item.attestation3,
              item.attestation4,
            ].map((i) => (_.isNil(i) ? "" : i))
          )
        )
      ) {
        return true;
      }
    }

    if (form === "attendance" && Array.isArray(a) && Array.isArray(b)) {
      if (
        _.isEqual(
          a.map((item) => ({
            attendee: item.attendee,
            business_unit: item.business_unit,
            stakeholder: item.stakeholder,
          })),
          b.map((item) => ({
            attendee: item.attendee,
            business_unit: item.business_unit,
            stakeholder: item.stakeholder,
          }))
        )
      ) {
        return true;
      }
    }

    if (form === "memo" && Array.isArray(a) && Array.isArray(b)) {
      if (
        _.isEqual(
          a.map((item) => ({
            mac_purpose: item.mac_purpose,
            mac_classification: item.mac_classification,
            code: item.code,
            mac_form: item.mac_form,
            mac_decision: item.mac_decision,
          })),
          b.map((item) => ({
            mac_purpose: item.mac_purpose,
            mac_classification: item.mac_classification,
            code: item.code,
            mac_form: item.mac_form,
            mac_decision: item.mac_decision,
          }))
        )
      ) {
        return true;
      }
    }

    if (form === "state" && Array.isArray(a) && Array.isArray(b)) {
      if (
        _.isEqual(
          a.map((item) => ({
            action_id: item.action_id,
            mac_action: item.mac_action,
            target_date: item.target_date,
            mac_result: item.mac_result,
            related_model: item.related_model,
            close_date: item.close_date,
            comment: item.comment,
          })),
          b.map((item) => ({
            action_id: item.action_id,
            mac_action: item.mac_action,
            target_date: item.target_date,
            mac_result: item.mac_result,
            related_model: item.related_model,
            close_date: item.close_date,
            comment: item.comment,
          }))
        )
      ) {
        return true;
      }
    }

    if (
      (Array.isArray(b) && _.isEqual(a, b?.join(","))) ||
      (Array.isArray(a) && _.isEqual(b, a?.join(",")))
    ) {
      return true;
    }

    if (typeof a === "string" && typeof b === "string") {
      if (
        _.isEqual(
          removeHtmlTags(a)?.trim()?.replace(/\s\s+/g, " "),
          removeHtmlTags(b)?.trim()?.replace(/\s\s+/g, " ")
        )
      ) {
        return true;
      }

      if (
        _.isEqual(
          removeEscapeCharacters(removeHtmlTags(a)?.trim()),
          removeHtmlTags(b)?.trim()
        )
      ) {
        return true;
      }
    }

    return false;
  }
}

const TemplatePage = ({
  tag,
  form,
  record,
  issueId,
  table,
  modelData,
  policyTopic,
  modelChangeTopic,
  isChangePage,
  isTreeChanged,
  latestVersion,
  originDynamicMetricData,
  monitorData,
  colorReference,
  gaugeData,
  macParams,
  setScrollTop,
  pageElement,
}) => {
  const history = useHistory();
  const { error, sendRequest, clearError } = useHttpClient();
  const {
    user,
    token,
    userGroup,
    fileTypeInToOutHandler,
    macFileTypeInToOutHandler,
    macFileType,
    socket,
    setFormHandler,
    setParamHandler,
    isSaveExecuted,
    saveAction,
    isPageDataChangedHandler,
    isPageDataNotChangedHandler,
    handleSaveSuspense,
    dataChangedArr,
    isTempPage,
    setNodeClickedHandler,
    setRecordHandler,
  } = useContext(UserContext);
  const [sequence, setSequence] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [oldDocument, setOldDocument] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [traceList, setTraceList] = useState([]);
  const [newAccessLog, setNewAccessLog] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [id, setId] = useState(1);
  const [metric, setMetric] = useState([]);
  const [formState, inputHandler] = useForm({}, false);
  const [dynamicMetricData, setDynamicMetricData] = useState([]);
  const [modelMetricData, setModelMetricData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { record2 } = useParams();
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [formDataObj, setFormDataObj] = useState({});
  const [modelFullVersionId, setModelFullVersionId] = useState("");
  const [modelOsfi, setModelOsfi] = useState();
  const [storedData, setStoredData] = useState({});
  const [quantitativeMetric, setQuantitativeMetric] = useState([]);
  const [qualitativeMetric, setQualitativeMetric] = useState([]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [score, setScore] = useState(0);
  const [trafficLight, setTrafficLight] = useState("");
  const [buttonOperation, setButtonOperation] = useState("");
  const [isTempPageExist, setIsTempPageExist] = useState(false);
  const [quantitativeColor, setQuantitativeColor] = useState([]);
  const [qualitativeColor, setQualitativeColor] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [maxSequence, setMaxSequence] = useState(0);
  const [isSequenceZeroExist, setIsSequenceZeroExist] = useState(false);
  const [isSequenceChanged, setIsSequenceChanged] = useState(false);
  const [gaugeDataSaver, setGaugeDataSaver] = useState(gaugeData);
  const [originDynamicMetricDataSaver, setOriginDynamicMetricDataSaver] =
    useState(originDynamicMetricData);
  const [colorReferenceSaver, setColorReferenceSaver] =
    useState(colorReference);

  const { display_name } = user || {};
  const status_option = Object.values(user["group access"]) || [];
  const URL = useLocation();

  const modelIdGroup = modelData?.map((item) => item.code);

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  let buttonAccess;
  if (user) {
    buttonAccess = user["menu access"] || {};
  }

  let modelName;
  if (form === "model") {
    modelName = modelData.map((data) => data.name);
  }

  if (record2) {
    record = record2;
  }
  if (policyTopic) {
    record = policyTopic.split("+")[1];
  }
  if (modelChangeTopic) {
    record = modelChangeTopic.split("+")[1];
  }

  if (macParams) {
    record = macParams.split("+")[0] + "+" + macParams.split("+")[1];
    macParams = macParams.split("+")[0] + "+" + macParams.split("+")[1];
  }

  let param;
  if (issueId) {
    param = issueId;
  } else if (!issueId && record) {
    param = record.split("+").slice(0, 2).join("+");
  } else {
    param = "test";
  }

  let tag3;
  if (form === "validation" && tag) {
    tag3 = tag
      .filter((item) => item.db_tablename === "validation assumption")
      .sort((a, b) => a.fe_order - b.fe_order);
  }

  let tag2, fileOption;
  if (tag) {
    tag2 = tag.filter(
      (item) => item.db_tablename === form && item.fe_isrender === true
    );
    fileOption = tag.filter(
      (item) => item.db_tablename === "documents" && item.fe_id === "file_type"
    );
  } else {
    tag2 = [];
    fileOption = [];
  }

  if (tag) {
    if (URL.pathname.split("/")[1] === "temp") {
      tag = tag.filter(
        (item) => item.db_tablename === "new" && item.fe_id !== ""
      );
    } else {
      tag = tag.filter(
        (item) => item.db_tablename === form && item.fe_id !== ""
      );
    }
  } else {
    tag = [];
  }

  let opmTag = [];
  if (tag && form === "opm") {
    opmTag = tag.filter(
      (item) => item.db_tablename === "opm" && item.fe_isrender === true
    );
  }

  let userOptions;
  if (userGroup && Array.isArray(userGroup)) {
    userOptions = userGroup.map((opt, i) => ({ name: opt, id: i }));
  }

  // if (
  //   colorReference &&
  //   Array.isArray(colorReference) &&
  //   formState.inputs?.framework?.value
  // ) {
  //   colorReference = colorReference.filter(
  //     (i) => i.framework === formState.inputs?.framework?.value
  //   );
  // }

  useEffect(() => {
    if (
      gaugeData &&
      Array.isArray(gaugeData) &&
      formState.inputs?.framework?.value
    ) {
      setGaugeDataSaver(
        gaugeData.filter(
          (i) => i.framework === formState.inputs?.framework?.value
        )
      );
    }
    if (originDynamicMetricData) {
      setOriginDynamicMetricDataSaver(originDynamicMetricData);
    }

    if (
      colorReference &&
      Array.isArray(colorReference) &&
      formState.inputs?.framework?.value
    ) {
      setColorReferenceSaver(
        colorReference.filter(
          (i) => i.framework === formState.inputs?.framework?.value
        )
      );
    }
  }, [gaugeData, originDynamicMetricData]);

  const ratingOption = { High: 3, Medium: 2, Low: 1 };

  const qualitativeOption = (stat) => {
    if (originDynamicMetricDataSaver && stat) {
      const opt = originDynamicMetricDataSaver.find(
        (i) => i.statistics_type === "Qualitative" && i.statistics === stat
      )?.option;
      return opt;
    } else return [];
  };

  const formAccess = Object.values(buttonAccess)[0]
    .filter((item) => item[0] === (isChangePage ? "Change" : table))
    .map((access) => access[1]);

  let dBKey = "";
  if (data) {
    if (form === "model") {
      if (isChangePage) {
        dBKey = `change+${record}+${sequence}`;
      } else {
        dBKey = `model+${record?.split("+")[0]}+${
          record?.split("+")[1]
        }+${sequence}`;
      }
    } else if (form === "issue" || form === "validation") {
      dBKey = issueId ? `${form}+${issueId}+${sequence}` : "";
    } else if (form === "opm") {
      dBKey = record ? `opm+${record.split("+")[1]}+${sequence}` : "";
    } else {
      dBKey = `${form}+${sequence}`;
    }
  }

  useEffect(() => {
    if (!isLoading) {
      socket.on("connection", () => {
        // console.log("connected");
      });
      socket.emit(
        "message",
        JSON.stringify({
          operation: "add",
          data: {
            [display_name]: [
              form,
              param.split("+")[0],
              param.split("+")[1],
              param.split("+")[2] ? param.split("+")[2] : "0",
            ],
          },
        })
      );
    }
  }, [param]);

  const onFocusFunction = () => {
    // do whatever when focus is gained

    socket.emit(
      "message",
      JSON.stringify({
        operation: "add",
        data: {
          [display_name]: [
            form,
            param.split("+")[0],
            param.split("+")[1],
            param.split("+")[2] ? param.split("+")[2] : "0",
          ],
        },
      })
    );
  };

  const onBlurFunction = () => {
    // do whatever when focus is lost

    socket.emit(
      "message",
      JSON.stringify({
        operation: "delete",
        data: {
          [display_name]: [
            form,
            param.split("+")[0],
            param.split("+")[1],
            param.split("+")[2] ? param.split("+")[2] : "0",
          ],
        },
      })
    );
  };

  useEffect(() => {
    onFocusFunction();

    window.addEventListener("focus", onFocusFunction);
    window.addEventListener("blur", onBlurFunction);
    onFocusFunction();

    return () => {
      onBlurFunction();

      window.removeEventListener("focus", onFocusFunction);
      window.removeEventListener("blur", onBlurFunction);
    };
  }, [param]);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();

      socket.emit(
        "message",
        JSON.stringify({
          operation: "clear",
          data: display_name,
        })
      );
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const quantitative_scale = (stats) => {
    if (
      Array.isArray(formState.inputs?.quantitative_metric?.value) &&
      Array.isArray(colorReferenceSaver)
    ) {
      const scale = formState.inputs?.quantitative_metric?.value.map(
        (metric) => ({
          statistics: metric.statistics,
          lower_bound: Math.min(
            ...colorReferenceSaver
              ?.filter(
                (i) =>
                  i.framework === formState.inputs?.framework?.value &&
                  i.statistics_type === "Quantitative" &&
                  i.statistics === metric?.statistics
              )
              ?.map((j) => j.lower_bound)
          ),
          upper_bound: Math.max(
            ...colorReferenceSaver
              ?.filter(
                (i) =>
                  i.framework === formState.inputs?.framework?.value &&
                  i.statistics_type === "Quantitative" &&
                  i.statistics === metric?.statistics
              )
              ?.map((j) => j.upper_bound)
          ),
        })
      );
      if (Array.isArray(scale)) {
        return scale.find((i) => i.statistics === stats);
      }
    }
  };

  const validationDateCheck = (opt) => {
    if (
      form === "validation" &&
      formState.inputs.certification_date &&
      formState.inputs.certification_date.value &&
      formState.inputs.certification_date.value !== ""
    ) {
      if (
        !(
          formState.inputs.end_date &&
          formState.inputs.start_date &&
          formState.inputs.end_date.value &&
          formState.inputs.start_date.value &&
          formState.inputs.start_date.value <=
            formState.inputs.end_date.value &&
          formState.inputs.end_date.value <=
            formState.inputs.certification_date.value
        )
      ) {
        opt !== "not show" &&
          Toast(
            "error",
            "Activity End Date should be later than Activity Start Date and earlier than Approval Date."
          );
        return false;
      }
    } else if (
      form === "validation" &&
      formState.inputs.end_date &&
      formState.inputs.end_date.value
    ) {
      if (
        !(
          formState.inputs.start_date &&
          formState.inputs.start_date.value &&
          formState.inputs.start_date.value <= formState.inputs.end_date.value
        )
      ) {
        opt !== "not show" &&
          Toast(
            "error",
            "Activity End Date should be later than Activity Start Date."
          );
        return false;
      }
    }
    return true;
  };

  const issueDateCheck = (opt) => {
    if (form === "issue") {
      if (
        formState.inputs.issue_date &&
        formState.inputs.issue_date.value &&
        formState.inputs.issue_date.value !== ""
      ) {
        if (
          formState.inputs.remediation_date &&
          formState.inputs.remediation_date.value &&
          formState.inputs.remediation_date.value !== "" &&
          formState.inputs.issue_date.value >
            formState.inputs.remediation_date.value
        ) {
          opt !== "not show" &&
            Toast(
              "error",
              "Issue Raised Date should be no later than Expected Resolution Date."
            );
          return false;
        }

        if (
          formState.inputs.date_closed &&
          formState.inputs.date_closed.value &&
          formState.inputs.date_closed.value !== ""
        ) {
          if (
            formState.inputs.issue_date.value >
            formState.inputs.date_closed.value
          ) {
            opt !== "not show" &&
              Toast(
                "error",
                "Issue Raised Date should be no later than Issue Closure Date."
              );
            return false;
          }
        }

        if (
          formState.inputs.revised_remediation_date &&
          formState.inputs.revised_remediation_date.value &&
          formState.inputs.revised_remediation_date.value !== ""
        ) {
          if (
            formState.inputs.issue_date.value >
            formState.inputs.revised_remediation_date.value
          ) {
            opt !== "not show" &&
              Toast(
                "error",
                "Issue Raised Date should be no later than Revised Expected Resolution Date."
              );
            return false;
          }
        }
      }

      if (
        formState.inputs.revised_remediation_date &&
        formState.inputs.revised_remediation_date.value &&
        formState.inputs.revised_remediation_date.value !== ""
      ) {
        if (
          formState.inputs.issue_date.value >
          formState.inputs.revised_remediation_date.value
        ) {
          opt !== "not show" &&
            Toast(
              "error",
              "Issue Raised Date should be no later than Revised Expected Resolution Date."
            );
          return false;
        }
      }
    }
    return true;
  };

  const modelIdCheck = (opt) => {
    if (!formState.inputs.version?.value || !formState.inputs.code?.value) {
      if (isChangePage) {
        Toast("error", "Please fill in the Model ID and Full Version ID.");
        return false;
      }
    } else if (
      isChangePage &&
      formState.inputs.code &&
      formState.inputs.version &&
      formState.inputs.version.value &&
      formState.inputs.code.value
    ) {
      if (
        formState.inputs.code.value.slice(-2) !==
        formState.inputs.version.value.slice(1, 3)
      ) {
        toast.error("Model ID and Full Version ID do not match.", {
          autoClose: false,
          position: "top-center",
          toastId: "Model ID and Full Version ID do not match.",
        });
        return false;
      }
    }
    return true;
  };

  const validationNetRiskingCheck = (opt) => {
    if (
      formState.inputs.net_rating &&
      formState.inputs.risk_rating &&
      formState.inputs.net_rating.value &&
      formState.inputs.risk_rating.value &&
      ratingOption[formState.inputs.net_rating.value] >
        ratingOption[formState.inputs.risk_rating.value]
    ) {
      opt !== "not show" &&
        Toast(
          "error",
          "Net Risk Rating should be lower than or equal to Inherent Risk Rating."
        );
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (URL.pathname === "/model" && isTempPage) {
      history.push("/temp");
    }
    // if (form === "model" && !isTempPage) {
    //   history.push("/model");
    // }
  }, [isTempPage]);

  useEffect(() => {
    db.testData
      .where("datakey")
      .equals(dBKey)
      .toArray()
      .then((data) => {
        setStoredData(data[0]?.data);
      });
  }, [form, record, isChangePage, sequence]);

  useEffect(() => {
    const fetchCalculateMetric = async () => {
      setIsCalculating(true);
      try {
        const responseData = await sendRequest(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/opm/metric/calculate?framework=${
            formState.inputs.framework.value
          }&code=${record.split("+")[0]}&version=${
            formState.inputs.version.value
          }`,
          "POST",
          JSON.stringify([
            ...formState.inputs.quantitative_metric.value.map((data) => {
              return {
                code: record.split("+")[0],
                version: formState.inputs.version.value || "",
                statistics_type: data.statistics_type,
                statistics: data.statistics,
                attestation1: "",
                attestation2: "",
                attestation3: "",
                attestation4: "",
                value1: data.value1 !== undefined ? data.value1 : null,
                value2: data.value2 !== undefined ? data.value2 : null,
                value3: data.value3 !== undefined ? data.value3 : null,
                value4: data.value4 !== undefined ? data.value4 : null,
                user_id: display_name,
                update_time: currentDate,
              };
            }),
            ...formState.inputs.qualitative_metric.value.map((data) => {
              return {
                code: record.split("+")[0],
                version: formState.inputs.version.value || "",
                statistics_type: data.statistics_type,
                statistics: data.statistics,
                attestation1:
                  data.attestation1 !== undefined ? data.attestation1 : "",
                attestation2:
                  data.attestation2 !== undefined ? data.attestation2 : "",
                attestation3:
                  data.attestation3 !== undefined ? data.attestation3 : "",
                attestation4:
                  data.attestation4 !== undefined ? data.attestation4 : "",
                value1: null,
                value2: null,
                value3: null,
                value4: null,
                user_id: display_name,
                update_time: currentDate,
              };
            }),
          ]),
          {
            "Content-Type": "application/json",
          }
        );
        setIsCalculating(false);
        responseData &&
          responseData["metric result"] &&
          setScore(responseData["metric result"]["weighted sum"]);
        responseData &&
          responseData["metric result"] &&
          setTrafficLight(responseData["metric result"]["traffic light"]);
        responseData &&
          responseData["dynamic metric"] &&
          setQuantitativeColor(
            responseData["dynamic metric"].filter(
              (i) => i.statistics_type === "Quantitative"
            )
          );
        responseData &&
          responseData["dynamic metric"] &&
          setQualitativeColor(
            responseData["dynamic metric"].filter(
              (i) => i.statistics_type === "Qualitative"
            )
          );
      } catch {}
    };

    if (
      form === "opm" &&
      !MetricCheck(
        formState.inputs?.quantitative_metric?.value,
        "Quantitative",
        quantitativeMetric.map((i) => i.isFourColsShow).includes(true),
        quantitative_scale
      ) &&
      !MetricCheck(
        formState.inputs?.qualitative_metric?.value,
        "Qualitative",
        qualitativeMetric.map((i) => i.isFourColsShow).includes(true)
      ) &&
      formState.inputs?.version?.value !== undefined &&
      formState.inputs?.version?.value !== ""
    ) {
      fetchCalculateMetric();
    }
  }, [
    formState.inputs?.quantitative_metric?.value,
    formState.inputs?.qualitative_metric?.value,
    formState.inputs?.version?.value,
    sequence,
  ]);

  useEffect(() => {
    if (!isLoading) {
      validationNetRiskingCheck();
    }
  }, [formState.inputs.net_rating?.value, formState.inputs.risk_rating?.value]);

  useEffect(() => {
    setAllData([]);
    setData([]);
    setFiles([]);
    setNewDocument([]);
    setTraceList([]);
    setNewAccessLog([]);
    setOldDocument([]);
    setId(1);

    const fetchData = async () => {
      setIsLoading(true);
      setIsSequenceChanged(true);
      try {
        const response = await sendRequest(
          form !== "mac"
            ? `${process.env.REACT_APP_BACKEND_URL}/${form}/${param}`
            : `${process.env.REACT_APP_BACKEND_URL}/${form}?mac_name=${
                macParams.split("+")[0]
              }&mac_date=${macParams.split("+")[1]}`
        );
        setIsSequenceChanged(false);
        if (
          (response.state && response.state.includes("does not exist")) ||
          (Array.isArray(response[`${form} data`]) &&
            response[`${form} data`].length === 0) ||
          response.error
        ) {
          setIsLoading(false);
          setRecordHandler("");
          return;
        }

        setData(response[`${form} data`][response[`${form} data`].length - 1]);
        setAllData(response[`${form} data`]);
        //setSequence(response[`${form} data`].length - 1);
        setPageCount(response[`${form} data`].length);
        setSequence(response[`${form} data`].length);
        if (response[`${form} data`]?.map((i) => i.sequence)?.includes(0)) {
          setIsTempPageExist(true);
        }

        if (!response[`${form} data`]?.map((i) => i.sequence)?.includes(0)) {
          setIsTempPageExist(false);
        }
        setOldDocument(response.documents);
        setTraceList(response.access_log);
        setNewAccessLog([]);
        response.access_log &&
          setId(
            response.access_log.length >= 1
              ? response.access_log[response.access_log.length - 1][0] + 1
              : 1
          );
        response["dynamic metric"] &&
          setDynamicMetricData(
            response["dynamic metric"].map((data) => {
              return {
                ...data,
                key: data.metric + data.threshold,
              };
            })
          );
        response["metric result"] &&
          setScore(response["metric result"]["weighted sum"]);
        response["metric result"] &&
          setTrafficLight(response["metric result"]["traffic light"]);
        response["dynamic metric"] &&
          setQuantitativeMetric(
            response["dynamic metric"]
              .filter((data) => data.statistics_type === "Quantitative")
              ?.map((item) => ({
                ...item,
                isFourColsShow: colorReferenceSaver
                  .filter(({ statistics }) => statistics === item.statistics)
                  ?.map((i) => i.opm_rule)
                  ?.includes("No"),
              }))
          );
        response["dynamic metric"] &&
          setQualitativeMetric(
            response["dynamic metric"]
              .filter((data) => data.statistics_type === "Qualitative")
              ?.map((item) => ({
                ...item,
                isFourColsShow: colorReferenceSaver
                  .filter(({ statistics }) => statistics === item.statistics)
                  ?.map((i) => i.opm_rule)
                  ?.includes("No"),
              }))
          );
        response?.["attendance option"] &&
          setAttendanceData(response?.["attendance option"]);
      } catch (err) {}
    };
    if (form) {
      fetchData();
    }
  }, [
    issueId,
    sendRequest,
    form,
    isChangePage,
    param,
    macParams,
    isFormChanged,
  ]);

  useEffect(() => {
    const asyncFunc = async () => {
      setIsSequenceChanged(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 1));
        setIsSequenceChanged(false);

        setData(allData[sequence - 1]);

        isChangePage && setModelFullVersionId(allData[sequence - 1].version);
        isChangePage && setModelOsfi(allData[sequence - 1].osfi);
        isChangePage && setOldDocument([]);
        allData[sequence - 1]["policy model"] &&
          setModelMetricData(allData[sequence - 1]["policy model"]);
        allData[sequence - 1]["change model"] &&
          setModelMetricData(allData[sequence - 1]["change model"]);
        setMaxSequence(Math.max(...allData.map((i) => i.sequence)));
        setIsSequenceZeroExist(
          Math.min(...allData.map((i) => i.sequence)) === Number(0)
        );
        setIsLoading(false);
      } catch (err) {}
    };
    if (allData.length > 0) {
      asyncFunc();
    }
  }, [sequence, allData]);

  useEffect(() => {
    if (!isLoading) {
      if (formState.inputs.code && formState.inputs.code.value) {
        setModelFullVersionId(`${formState.inputs.code.value.slice(-3)}CH000`);
        setModelOsfi(formState.inputs.code.value.replace(/[^0-9]/g, ""));
      }
    }
  }, [formState.inputs?.code?.value]);

  useEffect(() => {
    const setIndexDB = () => {
      storedData?.file && setFiles(storedData.file);
      storedData?.file && setNewDocument(storedData.newDocument);
      // storedData?.file && setOldDocument(storedData.oldDocument);
      storedData?.traceList && setTraceList(storedData.traceList);
      storedData?.newAccessLog && setNewAccessLog(storedData.newAccessLog);
      storedData?.traceList &&
        setId(
          storedData.traceList.length >= 1
            ? storedData.traceList[storedData.traceList.length - 1][0] + 1
            : 1
        );
    };
    if (storedData && !isLoading) {
      setIndexDB();
    }
  }, [storedData, isChangePage, data]);

  const uploadFileHandler = (file) => {
    if (Array.isArray(files)) {
      if (files.map((i) => i.name).includes(file.name)) {
        Toast("error", "File already added.");
        return;
      }
      setFiles([...files, file]);
      setNewDocument([
        ...newDocument,
        {
          file_description: "",
          filename: file.name,
          update_time: new Date(),
          user_id: display_name,
          files_status: "",
          file_type: "",
        },
      ]);
    }
  };

  const removeFileHandler = (name) => {
    setFiles(files.filter((doc) => doc.name !== name));
    setNewDocument(newDocument.filter((doc) => doc.filename !== name));
    URL.pathname.split("/")[1] === "temp" &&
      setOldDocument(oldDocument.filter((doc) => doc.filename !== name));
  };

  const fileChangeHandler = (id, value) => {
    setNewDocument(
      newDocument.map((doc, i) => {
        if ("file_" + doc.filename + "_type_" + (i + 1) === id) {
          doc.file_type = value;
        }
        if ("file_description_" + doc.filename === id) {
          doc.file_description = value;
        }
        return doc;
      })
    );
  };

  const formStatusChange = (value) => {
    let newLog;
    if (form === "issue" || form === "validation") {
      newLog = [
        id,
        record.split("+")[0],
        record.split("+")[1],
        issueId.split("+")[2],
        value,
        display_name,
        currentDate,
      ];
    } else if (form === "opm") {
      newLog = [
        id,
        record.split("+")[0],
        data.version,
        value,
        display_name,
        currentDate,
      ];
    } else if (form === "policy" || form === "model change") {
      newLog = [id, data.topic, value, display_name, currentDate];
    } else {
      newLog = [
        id,
        record.split("+")[0],
        record.split("+")[1],
        value,
        display_name,
        currentDate,
      ];
    }

    setTraceList([...traceList, newLog]);
    setNewAccessLog([...newAccessLog, newLog]);
    setId(id + 1);
  };

  const setMetricDataHandler = (metricData) => {
    if (form === "policy" || form === "model change" || form === "in use") {
      setMetric(
        metricData.map((data) => {
          return {
            topic: data.topic,
            version: data.version,
            metric: data.metric,
            threshold: data.threshold,
            statistic: data.statistic,
          };
        })
      );
    } else {
      setMetric(
        metricData.map((data) => {
          return {
            code: data.code,
            version: data.version,
            metric: data.metric,
            statistic: data.statistic,
          };
        })
      );
    }
  };

  const setModelDataHandler = (metricData) => {
    setModelMetricData(
      metricData.map((model) => {
        return {
          topic: data.topic,
          sequence: pageCount,
          code:
            model.code !== undefined
              ? model.code
              : modelData.filter((i) => i.name === model.name)[0]?.code,
          name: model.name,
          user_id: model.user_id !== undefined ? model.user_id : display_name,
          update_time:
            model.update_time !== undefined ? model.update_time : currentDate,
        };
      })
    );
  };

  const validationApprovalRatingCheck = () => {
    if (
      form === "validation" &&
      formState.inputs.rating &&
      formState.inputs.rating.value &&
      formState.inputs.validation_status &&
      formState.inputs.validation_status
    ) {
      if (
        !traceList.flat().includes("Report MD Reviewed and Agreed") &&
        formState.inputs.validation_status?.value !==
          "Report MD Reviewed and Agreed" &&
        (formState.inputs.rating.value === "Approval" ||
          formState.inputs.rating.value === "Approved With Condition(s)")
      ) {
        toast.error(
          "Validation Status 'Report MD Reviewed and Agreed' is required.",
          {
            autoClose: false,
            position: "top-center",
            toastId: "validationStatusError",
          }
        );
        return false;
      }
    }
    return true;
  };

  const modelIdFormatCheck = (opt) => {
    if (form === "model") {
      if (
        formState.inputs.code &&
        (!formState.inputs.code.value ||
          !/[A-Z][A-Z][A-Z]+\.\d\.\d\d\.\d\d\.V\d\d/.test(
            formState.inputs.code.value
          ))
      ) {
        opt !== "not show" && Toast("error", "Model ID has an invalid format.");

        return false;
      }

      if (
        formState.inputs.version?.value &&
        (!formState.inputs.version?.value ||
          !/V\d\dCH\d\d\d/.test(formState.inputs.version.value) ||
          formState.inputs.version.value.length !== 8)
      ) {
        opt !== "not show" &&
          Toast("error", "Full Version ID has an invalid format.");
        return false;
      }

      if (
        !formState.inputs.name ||
        !formState.inputs.name.value ||
        formState.inputs.name.value.trim() === ""
      ) {
        opt !== "not show" && Toast("error", "Model Name is required.");
        return false;
      }
    }
    return true;
  };

  const versionSelectedCheck = (opt) => {
    if (
      !(form === "policy" || form === "model change" || form === "in use") &&
      !record.split("+")[1]
    ) {
      opt !== "not show" && Toast("info", "No Model Version Selected.");
      return false;
    }
    return true;
  };

  const modelStatusSelectedCheck = (opt) => {
    if (
      form === "model" &&
      formState.inputs.model_status &&
      !formState.inputs.model_status.value
    ) {
      opt !== "not show" && Toast("error", "No Model Status Selected.");
      return false;
    }
    return true;
  };

  const validationMrmActivityIdCheck = (opt) => {
    if (
      form === "validation" &&
      (!formState.inputs.validation_id ||
        !formState.inputs.validation_id.value ||
        formState.inputs.validation_id.value === "" ||
        !/ATV\d\d\d\d/.test(formState.inputs.validation_id.value))
    ) {
      opt !== "not show" &&
        Toast("error", "MRM Activity ID has an invalid format.");
      return false;
    }
    return true;
  };

  const validationStatusCheck = (opt) => {
    if (
      form === "validation" &&
      (!formState.inputs.validation_status ||
        !formState.inputs.validation_status.value ||
        formState.inputs.validation_status.value === "")
    ) {
      opt !== "not show" &&
        Toast("error", "Please input correct Validation Status.");
      return false;
    }
    return true;
  };

  const validationExpiryDateCheck = (opt) => {
    if (
      form === "validation" &&
      formState.inputs.approver &&
      formState.inputs.approver.value !== "CRO Exception Approval"
    ) {
      if (
        formState.inputs.expire_date &&
        formState.inputs.expire_date.value !== ""
      ) {
        opt !== "not show" &&
          Toast(
            "error",
            "Please check Model Approver and CRO Exception Expiry Date."
          );
        return false;
      }
    }
    return true;
  };

  const validationReportDateCheck = (opt) => {
    if (
      form === "validation" &&
      formState.inputs.report_date.value &&
      formState.inputs.report_date.value !== ""
    ) {
      if (
        !formState.inputs.start_date.value ||
        formState.inputs.start_date.value === "" ||
        (formState.inputs.start_date.value &&
          formState.inputs.start_date.value !== "" &&
          formState.inputs.start_date.value >
            formState.inputs.report_date.value)
      ) {
        opt !== "not show" &&
          Toast(
            "error",
            "Report Date should be later than Activity Start Date."
          );
        return false;
      }
    }
    return true;
  };

  const ValidationActivityIdCheck = (opt) => {
    if (form === "validation") {
      if (
        !/ATV\d\d\d\d/.test(formState.inputs.validation_id?.value) ||
        formState.inputs.validation_id?.value?.length !== 7
      ) {
        opt !== "not show" &&
          Toast("error", "MRM Activity ID has an invalid format.");
        return false;
      }
    }
    return true;
  };

  const issueMultiFieldCheck = (opt) => {
    if (form === "issue") {
      if (
        !(
          formState.inputs.raise?.value === "External Auditor" ||
          formState.inputs.raise?.value === "External Vetters"
        ) &&
        formState.inputs.auditor &&
        formState.inputs.auditor.value !== ""
      ) {
        opt !== "not show" &&
          Toast(
            "error",
            "The information of External Auditors/Vetters and Raised by is mismatched."
          );
        return false;
      }

      if (
        formState.inputs.issue_status &&
        formState.inputs.issue_status.value === "Open"
      ) {
        if (
          (formState.inputs.approver &&
            formState.inputs.approver?.value &&
            formState.inputs.approver.value !== "") ||
          (formState.inputs.date_closed &&
            formState.inputs.date_closed?.value &&
            formState.inputs.date_closed.value !== "") ||
          (formState.inputs.record &&
            formState.inputs.record?.value &&
            formState.inputs.record.value !== "")
        ) {
          Toast(
            "error",
            "For the Open Issues, Issue Closure Record, Issue Closure Approver, and Issue Closure Date should be empty."
          );
          return false;
        }
      }
    }
    return true;
  };

  const modelProposePurposeCheck = (opt) => {
    if (form === "model") {
      if (
        !formState.inputs.propose.value ||
        !formState.inputs.propose.value.trim() === ""
      ) {
        opt !== "not show" &&
          Toast("error", "Please input Model Develop Intended Purpose.");
        return false;
      }
    }
    return true;
  };

  const modelShortDescriptionCheck = (opt) => {
    if (form === "model") {
      if (
        !formState.inputs.short_description.value ||
        !formState.inputs.short_description.value.trim() === ""
      ) {
        opt !== "not show" &&
          Toast("error", "Please input Model Short Description.");
        return false;
      }
    }
    return true;
  };

  const fileTypeCheck = (opt) => {
    if (Array.from(newDocument, (x) => x.file_type).includes("")) {
      opt !== "not show" && Toast("error", "Please specify the File Type.");
      return false;
    }
    return true;
  };

  const macInputCheck = (opt) => {
    if (formState.inputs?.mac_date?.value === "") {
      opt !== "not show" && Toast("error", "Please enter the MAC Date.");
      return false;
    }
    if (Array.isArray(formState.inputs?.state?.value)) {
      if (
        checkIfDuplicateExists(
          Array.from(formState.inputs?.state?.value, (x) => x.action_id)
        )
      ) {
        opt !== "not show" &&
          Toast("error", "Please do not input duplicate Item ID.");
        return false;
      }
    }
    return true;
  };

  const opmInputCheck = (opt) => {
    if (
      !formState.inputs?.opm_fit?.value ||
      formState.inputs?.opm_fit?.value === "" ||
      !formState.inputs?.framework?.value ||
      formState.inputs?.framework?.value === ""
    ) {
      if (form === "opm") {
        opt !== "not show" &&
          Toast("error", "Please input Final Outcome and OPM Framework");
        return false;
      }
    }
    return true;
  };

  const inputCheck = (opt) => {
    if (modelIdFormatCheck(opt) === false) {
      return false;
    }

    if (ValidationActivityIdCheck(opt) === false) {
      return false;
    }

    if (versionSelectedCheck(opt) === false) {
      return false;
    }

    if (modelStatusSelectedCheck(opt) === false) {
      return false;
    }

    if (validationMrmActivityIdCheck(opt) === false) {
      return false;
    }

    if (validationStatusCheck(opt) === false) {
      return false;
    }

    if (validationExpiryDateCheck(opt) === false) {
      return false;
    }

    if (issueMultiFieldCheck(opt) === false) {
      return false;
    }

    if (validationNetRiskingCheck(opt) === false) {
      return false;
    }

    if (validationDateCheck(opt) === false) {
      return false;
    }

    if (issueDateCheck(opt) === false) {
      return false;
    }

    if (modelIdCheck(opt) === false) {
      return false;
    }

    if (validationApprovalRatingCheck(opt) === false) {
      return false;
    }

    if (validationReportDateCheck(opt) === false) {
      return false;
    }

    if (modelProposePurposeCheck(opt) === false) {
      return false;
    }

    if (modelShortDescriptionCheck(opt) === false) {
      return false;
    }

    if (fileTypeCheck(opt) === false) {
      return false;
    }

    if (macInputCheck(opt) === false) {
      return false;
    }

    if (opmInputCheck(opt) === false) {
      return false;
    }

    if (
      form === "opm" &&
      (MetricCheck(
        formState.inputs?.quantitative_metric?.value,
        "Quantitative",
        quantitativeMetric.map((i) => i.isFourColsShow).includes(true),
        quantitative_scale,
        true
      ) ||
        MetricCheck(
          formState.inputs?.qualitative_metric?.value,
          "Qualitative",
          qualitativeMetric.map((i) => i.isFourColsShow).includes(true),
          null,
          true
        ))
    ) {
      return false;
    }

    if (
      form === "mac" &&
      isAttendanceIncludesNonNumber(formState.inputs?.attendance?.value)
    ) {
      opt !== "not show" &&
        Toast("error", "Please do not input duplicate Attendee.");
      return false;
    }

    if (
      form === "issue" &&
      (!formState.inputs.issue_id.value ||
        formState.inputs.issue_id.value === "" ||
        !formState.inputs.name.value ||
        formState.inputs.name.value.trim() === "" ||
        !formState.inputs.issue_status.value ||
        formState.inputs.issue_status.value === "" ||
        !formState.inputs.raise.value ||
        formState.inputs.raise.value === "")
    ) {
      opt !== "not show" && Toast("error", "Please input the required items.");
      return false;
    }

    if (
      form === "model" &&
      formState.inputs.vendor &&
      formState.inputs.vendor.value === "No"
    ) {
      if (
        formState.inputs.vendor_developer &&
        formState.inputs.vendor_developer.value !== ""
      ) {
        opt !== "not show" &&
          Toast.error("error", "Please check Vendor Model and Vendor Name.");
        return false;
      }
    }
    return true;
  };

  const handleClickOpen = (operation) => {
    if (!inputCheck()) {
      return;
    }
    setOpen(true);
    const formData = new FormData();

    form === "opm" && formData.append("dynamic metric", JSON.stringify(metric));
    form === "opm" && formData.append("next_submission", data.next_submission);
    form === "opm" && formData.append("version", data.version);

    tag2.forEach((item) => {
      if (
        item.fe_id !== "update_time" &&
        ((item.fe_id !== "upstream_name" &&
          item.fe_id !== "downstream_name" &&
          item.fe_id !== "downstream_code" &&
          item.fe_id !== "upstream_code" &&
          item.fe_id !== "risk_rating" &&
          item.fe_id !== "net_rating" &&
          item.fe_id !== "approve_purpose" &&
          item.fe_id !== "certification_date") ||
          form !== "model")
      ) {
        formData.append(
          item.fe_id,
          formState.inputs[item.fe_id]
            ? formState.inputs[item.fe_id].value
              ? formState.inputs[item.fe_id].value
              : ""
            : data.version
            ? data.version
            : ""
        );
      }
    });

    if (
      formState.inputs.upstream_name &&
      formState.inputs.upstream_name.value &&
      formState.inputs.upstream_name.value !== ""
    ) {
      formData.append(
        "upstream_name",
        formState.inputs.upstream_name.value
          ? JSON.stringify(formState.inputs.upstream_name.value)
          : ""
      );
      formData.append(
        "upstream_code",
        JSON.stringify(
          formState.inputs.upstream_name.value.map((item, i) =>
            modelData.filter((model) => model.name === item)[0]
              ? modelData.filter((model) => model.name === item)[0].code
              : data?.["upstream_code"]?.[data?.upstream_name?.indexOf(item)] ||
                ""
          )
        )
      );
    }
    if (
      formState.inputs.downstream_name &&
      formState.inputs.downstream_name.value &&
      formState.inputs.downstream_name.value !== ""
    ) {
      formData.append(
        "downstream_name",
        formState.inputs.downstream_name.value
          ? JSON.stringify(formState.inputs.downstream_name.value)
          : ""
      );
      formData.append(
        "downstream_code",
        JSON.stringify(
          formState.inputs.downstream_name.value.map((item, i) =>
            modelData.filter((model) => model.name === item)[0]
              ? modelData.filter((model) => model.name === item)[0].code
              : data?.["downstream_code"]?.[
                  data?.downstream_name?.indexOf(item)
                ] || ""
          )
        )
      );
    }

    let arr2 = [];

    if (URL.pathname?.split("/")?.[1] === "temp") {
      const tempTag = tag.filter((i) => i.fe_isrender === true);
      for (let i = 0; i < tempTag.length; i++) {
        arr2.push({
          id: tempTag[i].fe_id,
          attribute: tempTag[i].fe_label,
          previous: data?.[tempTag[i].fe_id],
          current: formState.inputs?.[tempTag[i].fe_id]?.value,
        });
      }
    } else {
      for (let i = 0; i < tag2.length; i++) {
        arr2.push({
          id: tag2[i].fe_order,
          attribute: tag2[i].fe_label,
          previous:
            tag2[i].fe_element === "textEditor"
              ? data?.[tag2[i].fe_id]?.replace(/<[^>]+>|&[^;]+;/g, "")
              : data?.[tag2[i].fe_id],
          current:
            tag2[i].fe_element === "textEditor"
              ? formState.inputs?.[tag2[i].fe_id]?.value?.replace(
                  /<[^>]+>|&[^;]+;/g,
                  ""
                )
              : formState.inputs?.[tag2[i].fe_id]?.value,
        });
      }
    }

    if (newDocument?.length > 0) {
      arr2.push({
        id: "files",
        attribute: "Files",
        previous: "",
        current: Array.from(newDocument, (x) => x.filename)?.join("; "),
      });
    }

    setTableData(
      arr2
        .sort((a, b) => a.id - b.id)
        .map((item, index) => ({
          ...item,
          id: index + 1,
        }))
    );
    setButtonOperation(operation);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isButtonDisabled = () => {
    if (user?.user_group?.[0] !== "Admin") {
      if (data.sequence === 0 && data.user_id !== display_name) {
        return true;
      }
    }
    if (sequence !== pageCount) {
      return true;
    }
    // only admin can operate mac
    if (user?.user_group?.[0] !== "Admin" && form === "mac") {
      return true;
    }
    // end only admin can operate mac
    if (
      form === "policy" ||
      form === "in use" ||
      form === "model change" ||
      form === "mac"
    ) {
      return false;
    } else if (formAccess.includes("Update")) {
      return false;
    } else {
      return true;
    }
  };

  const deleteButtonDisabledHandler = () => {
    if (data?.sequence > 0) {
      if (
        user?.user_group?.[0] === "Admin" &&
        data?.sequence === maxSequence &&
        !isSequenceZeroExist
      ) {
        return false;
      }
      return true;
    }
    if (user?.user_group?.[0] !== "Admin") {
      if (data?.sequence === 0 && data.user_id !== display_name) {
        return true;
      }
    }
    return false;
  };

  const formUpdateHandler = async (event) => {
    event.preventDefault();

    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);

    if (form === "policy" || form === "model change" || form === "in use") {
      try {
        const formData = new FormData();
        formData.append("topic", data.topic);
        formData.append("sequence", pageCount);
        formData.append("user_id", display_name);
        formData.append("update_time", currentDate);
        formData.append("access_log", JSON.stringify(newAccessLog));
        form === "policy" &&
          formData.append("policy model", JSON.stringify(modelMetricData));
        form === "model change" &&
          formData.append("change model", JSON.stringify(modelMetricData));
        form === "in use" &&
          formData.append("dynamic metric", JSON.stringify(metric));
        newDocument.length > 0 &&
          formData.append(
            "file_description",
            JSON.stringify(
              Array.from(newDocument, (x) =>
                fileTypeInToOutHandler(x.file_description)
              )
            )
          );
        newDocument.length > 0 &&
          formData.append(
            "file_type",
            JSON.stringify(
              Array.from(newDocument, (x) =>
                fileTypeInToOutHandler(x.file_type)
              )
            )
          );
        tag.forEach((item) => {
          if (
            formState.inputs[item.fe_id] &&
            formState.inputs[item.fe_id].value !== ""
          ) {
            formData.append(
              item.fe_id,
              formState.inputs[item.fe_id]
                ? formState.inputs[item.fe_id].value
                : ""
            );
          }
        });
        if (files) {
          for (let i = 0; i < files.length; i++) {
            formData.append(`file${i + 1}`, files[i]);
          }
        }

        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/${form}/submit`,
          "PUT",
          formData,
          headers
        );

        if (res.state) {
          alert("Data Updated.", 2);
          setIsFormChanged((prev) => !prev); //to refresh the table
        } else {
          alert("Failed to Update.", 2);
        }
      } catch (err) {}
    } else if (form === "mac") {
      try {
        setOpen(false);
        const formData = new FormData();

        const memoBody = Array.isArray(formState.inputs?.memo?.value)
          ? formState.inputs?.memo?.value.map((item, i) => ({
              mac_name: macParams?.split("+")?.[0] || "",
              mac_date: formState.inputs?.mac_date?.value || "",
              memo_id: i + 1,
              mac_purpose: item.mac_purpose || "",
              mac_classification: item.mac_classification || "",
              mac_form: item.mac_form || "",
              mac_decision: item.mac_decision || "",
              code: item.code || "",
              placeholder1: "",
              placeholder2: "",
              placeholder3: "",
              placeholder4: "",
              placeholder5: "",
            }))
          : [];

        const stateBody = Array.isArray(formState.inputs?.state?.value)
          ? formState.inputs?.state?.value?.map((item, i) => ({
              mac_name: macParams?.split("+")?.[0] || "",
              mac_date: formState.inputs?.mac_date?.value || "",
              action_id: item.action_id || "",
              mac_action: item.mac_action || "",
              target_date: item.target_date || "",
              mac_result: item.mac_result || "",
              related_model: item.related_model || "",
              comment: item.comment || "",
              close_date: item.close_date || "",
              placeholder1: "",
              placeholder2: "",
              placeholder3: "",
              placeholder4: "",
              placeholder5: "",
            }))
          : [];

        const attendanceBody = Array.isArray(
          formState.inputs?.attendance?.value
        )
          ? formState.inputs?.attendance?.value.map((item, i) => ({
              mac_name: macParams?.split("+")?.[0] || "",
              mac_date: formState.inputs?.mac_date?.value || "",
              attend_id: i + 1,
              attendee: item.attendee || "",
              stakeholder:
                attendanceData.find(
                  ({ attendee }) => attendee === item.attendee
                )?.stakeholder || item.stakeholder,
              business_unit:
                attendanceData.find(
                  ({ attendee }) => attendee === item.attendee
                )?.business_unit || item.business_unit,
              placeholder1: "",
              placeholder2: "",
              placeholder3: "",
              placeholder4: "",
              placeholder5: "",
            }))
          : [];

        formData.append("user_id", display_name);
        formData.append("update_time", currentDate);
        formData.append("sequence", data.sequence);
        formData.append("mac_name", macParams?.split("+")?.[0]);
        newDocument.length > 0 &&
          formData.append(
            "file_description",
            JSON.stringify(
              Array.from(newDocument, (x) =>
                fileTypeInToOutHandler(x.file_description)
              )
            )
          );
        newDocument.length > 0 &&
          formData.append(
            "file_type",
            JSON.stringify(
              Array.from(newDocument, (x) =>
                macFileTypeInToOutHandler(x.file_type)
              )
            )
          );

        tag.forEach((item) => {
          if (
            item.fe_element !== "autoFill" &&
            item.fe_id !== "update_time" &&
            item.fe_id !== "user_id" &&
            item.fe_id !== "sequence" &&
            item.fe_id !== "business_unit" &&
            item.fe_id !== "mac_name"
          ) {
            formData.append(
              item.fe_id,
              formState.inputs[item.fe_id]?.value
                ? formState.inputs[item.fe_id]?.value
                : ""
            );
          }
        });

        tag.forEach((item) => {
          if (item.fe_id === "business_unit") {
            formData.append(
              item.fe_id,
              formState.inputs[item.fe_id]?.value
                ? JSON.stringify(formState.inputs[item.fe_id]?.value)
                : JSON.stringify([])
            );
          }
        });

        formData.append("memo", JSON.stringify(memoBody));
        formData.append("state", JSON.stringify(stateBody));
        formData.append("attendance", JSON.stringify(attendanceBody));

        if (files) {
          for (let i = 0; i < files.length; i++) {
            formData.append(`file${i + 1}`, files[i]);
          }
        }

        setIsLoading(true);

        let inventoryValue = true;

        if (buttonOperation === "save") {
          inventoryValue = false;
        }

        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/mac?inventory=${inventoryValue}`,
          "PUT",
          formData,
          headers
        );

        setIsLoading(false);

        if (res.state) {
          for (const key in res.state) {
            if (res.state[key] === "updated" || res.state[key] === "deleted") {
              Toast("success", "Successfully Updated.");
              setIsFormChanged((prev) => !prev);
              break;
            }
          }
          if (
            !Object.values(res.state).includes("updated") &&
            !Object.values(res.state).includes("deleted")
          ) {
            Toast("info", "No field changed.");
          }
        }
      } catch (err) {
        //console.log(err);
      }
    } else {
      // send this to the backend
      try {
        setOpen(false);

        const formData = new FormData();

        formData.append("form", table);

        form === "validation" && formData.append("code", record.split("+")[0]);
        form === "validation" &&
          formData.append("version", record.split("+")[1]);
        formData.append("access_log", JSON.stringify(newAccessLog));

        formData.append("user_id", display_name);

        form === "model" && formData.append("risk_rating", "");
        form === "model" && formData.append("net_rating", "");
        form === "model" && formData.append("approve_purpose", "");
        form === "model" && formData.append("certification_date", "");
        form === "model" && formData.append("approver", "");
        form === "model" && formData.append("expire_date", "");
        form === "opm" &&
          formData.append(
            "dynamic metric",
            JSON.stringify([
              ...formState.inputs.quantitative_metric.value.map((data) => {
                return {
                  code: record.split("+")[0],
                  version: formState.inputs.version.value || "",
                  statistics_type: data.statistics_type,
                  statistics: data.statistics,
                  attestation1: "",
                  attestation2: "",
                  attestation3: "",
                  attestation4: "",
                  value1: data.value1 !== undefined ? data.value1 : null,
                  value2: data.value2 !== undefined ? data.value2 : null,
                  value3: data.value3 !== undefined ? data.value3 : null,
                  value4: data.value4 !== undefined ? data.value4 : null,
                  user_id: display_name,
                  update_time: currentDate,
                };
              }),
              ...formState.inputs.qualitative_metric.value.map((data) => {
                return {
                  code: record.split("+")[0],
                  version: formState.inputs.version.value || "",
                  statistics_type: data.statistics_type,
                  statistics: data.statistics,
                  attestation1:
                    data.attestation1 !== undefined ? data.attestation1 : "",
                  attestation2:
                    data.attestation2 !== undefined ? data.attestation2 : "",
                  attestation3:
                    data.attestation3 !== undefined ? data.attestation3 : "",
                  attestation4:
                    data.attestation4 !== undefined ? data.attestation4 : "",
                  value1: null,
                  value2: null,
                  value3: null,
                  value4: null,
                  user_id: display_name,
                  update_time: currentDate,
                };
              }),
            ])
          );
        // form === "opm" &&
        //   formData.append("next_submission", data.next_submission);
        newDocument.length > 0 &&
          formData.append(
            "file_description",
            JSON.stringify(
              Array.from(newDocument, (x) =>
                fileTypeInToOutHandler(x.file_description)
              )
            )
          );
        newDocument.length > 0 &&
          formData.append(
            "file_type",
            JSON.stringify(
              Array.from(newDocument, (x) =>
                fileTypeInToOutHandler(x.file_type)
              )
            )
          );

        formData.append("update_time", currentDate);
        tag.forEach((item) => {
          if (
            item.fe_element !== "autoFill" &&
            item.fe_element !== "autoComplete" &&
            item.fe_type !== "number" &&
            item.fe_id !== "update_time" &&
            item.fe_id !== "user_id" &&
            ((item.fe_id !== "upstream_name" &&
              item.fe_id !== "downstream_name" &&
              item.fe_id !== "downstream_code" &&
              item.fe_id !== "upstream_code" &&
              item.fe_id !== "risk_rating" &&
              item.fe_id !== "net_rating" &&
              item.fe_id !== "approve_purpose" &&
              item.fe_id !== "certification_date" &&
              item.fe_id !== "approver" &&
              item.fe_id !== "expire_date") ||
              form !== "model") &&
            ((item.fe_id !== "version" &&
              item.fe_id !== "user_id" &&
              item.fe_id !== "code") ||
              form !== "validation")
          ) {
            formData.append(
              item.fe_id,
              formState.inputs[item.fe_id]
                ? formState.inputs[item.fe_id].value
                  ? formState.inputs[item.fe_id].value
                  : ""
                : data[item.fe_id] !== undefined
                ? data[item.fe_id]
                : ""
            );
          }
        });

        tag.forEach((item) => {
          if (item.fe_element === "autoFill") {
            formData.append(
              item.fe_id,
              formState.inputs[item.fe_id].value &&
                Array.isArray(formState.inputs[item.fe_id].value)
                ? JSON.stringify(
                    formState.inputs[item.fe_id].value.map((val) => {
                      if (userOptions.find(({ id }) => id === val)) {
                        return userOptions.find(({ id }) => id === val).name;
                      } else {
                        return val;
                      }
                    })
                  )
                : JSON.stringify([])
            );
          }
        });

        tag.forEach((item) => {
          if (item.fe_type === "number") {
            formData.append(
              item.fe_id,
              formState.inputs[item.fe_id]
                ? formState.inputs[item.fe_id].value
                  ? formState.inputs[item.fe_id].value
                  : 0
                : data[item.fe_id]
                ? data[item.fe_id]
                : 0
            );
          }
        });

        tag.forEach((item) => {
          if (
            item.fe_element === "autoComplete" &&
            item.fe_id !== "upstream_code" &&
            item.fe_id !== "downstream_code"
          ) {
            formData.append(
              item.fe_id,
              formState.inputs[item.fe_id].value
                ? JSON.stringify(formState.inputs[item.fe_id].value)
                : JSON.stringify([])
            );
          }
        });

        if (
          formState.inputs.upstream_name &&
          formState.inputs.upstream_name.value &&
          formState.inputs.upstream_name.value !== ""
        ) {
          // formData.append(
          //   "upstream_name",
          //   formState.inputs.upstream_name.value
          //     ? JSON.stringify(formState.inputs.upstream_name.value)
          //     : JSON.stringify([])
          // );

          formData.append(
            "upstream_code",
            JSON.stringify(
              formState.inputs.upstream_name.value.map((item, i) =>
                modelData.filter((model) => model.name === item)[0]
                  ? modelData.filter((model) => model.name === item)[0].code
                  : data?.["upstream_code"]?.[
                      data?.upstream_name?.indexOf(item)
                    ] || ""
              )
            )
          );
        }
        if (
          formState.inputs.downstream_name &&
          formState.inputs.downstream_name.value &&
          formState.inputs.downstream_name.value !== ""
        ) {
          formData.append(
            "downstream_code",
            JSON.stringify(
              formState.inputs.downstream_name.value.map((item, i) =>
                modelData.filter((model) => model.name === item)[0]
                  ? modelData.filter((model) => model.name === item)[0].code
                  : data?.["downstream_code"]?.[
                      data?.downstream_name?.indexOf(item)
                    ] || ""
              )
            )
          );
        }

        if (form === "validation") {
          tag3.forEach((item) => {
            formData.append(
              item.fe_id,
              !formState.inputs[item.fe_id] ||
                formState.inputs[item.fe_id].length === 0 ||
                !formState.inputs[item.fe_id].value
                ? JSON.stringify([])
                : JSON.stringify(
                    formState.inputs[item.fe_id].value.filter(
                      (i) => i.condition !== ""
                    )
                  )
            );
          });
        }

        for (let i = 0; i < Object.keys(data).length; i++) {
          if (!formData.has(Object.keys(data)[i])) {
            alert(`No ${Object.keys(data)[i]}`, 3);
            return;
          }
        }

        if (files) {
          for (let i = 0; i < files.length; i++) {
            formData.append(`file${i + 1}`, files[i]);
          }
        }

        setIsLoading(true);

        let inventoryValue = true;
        if (URL.pathname.split("/")[1] === "temp") {
          if (
            formState.inputs.model_status.value === "Not-Submitted" ||
            formState.inputs.model_status.value === "Submitted"
          ) {
            inventoryValue = false;
          }
        } else {
          if (buttonOperation === "save") {
            inventoryValue = false;
          }
        }

        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/${form}/submit?inventory=${inventoryValue}`,
          "PUT",
          formData,
          headers
        );
        setIsLoading(false);

        if (
          URL.pathname.split("/")[1] === "temp" &&
          (formState.inputs.model_status.value === "Not-Submitted" ||
            formState.inputs.model_status.value === "Submitted")
        ) {
          if (res.access_log) {
            Toast("success", "Saved.");
            clearIndexDB();
            deleteChangedData();
            setIsFormChanged((prev) => !prev);
          }
        } else if (
          URL.pathname.split("/")[1] === "temp" &&
          formState.inputs.model_status.value !== "Not-Submitted" &&
          formState.inputs.model_status.value !== "Submitted"
        ) {
          if (res.access_log) {
            Toast("success", "Saved.");
            history.push("/model/" + param);
            deleteChangedData();
            clearIndexDB();
            isTreeChanged();
          }
        }

        if (!res.state && res.access_log) {
          Toast("success", "Saved.");
          deleteDataFromIndexDB(dBKey);
          deleteChangedData();
          setIsFormChanged((prev) => !prev);
        }

        if (
          URL.pathname.split("/")[1] !== "temp" &&
          res.state &&
          res.access_log
        ) {
          for (const key in res.state) {
            if (res.state[key] === "updated" || res.state[key] === "deleted") {
              Toast("success", "Successfully Updated.");
              deleteChangedData();
              deleteDataFromIndexDB(dBKey);
              setIsFormChanged((prev) => !prev);

              if (
                isChangePage ||
                (URL.pathname.split("/")[1] === "temp" &&
                  formState.inputs.model_status.value !== "Not-Submitted" &&
                  formState.inputs.model_status.value !== "Submitted")
              ) {
                deleteDataFromIndexDB(dBKey);
                deleteChangedData();
                isTreeChanged();
              }
              break;
            }
          }
          if (
            !Object.values(res.state).includes("updated") &&
            !Object.values(res.state).includes("deleted")
          ) {
            Toast("info", "No field changed.");
            deleteChangedData();
            setIsFormChanged((prev) => !prev);
          }

          setStoredData();
        } else {
          Toast("error", res, "Please check the input data.");
          return;
        }
      } catch (err) {
        Toast("error", err);
      }
    }
  };

  const tempDeleteHandler = async (e) => {
    e.preventDefault();
    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);
    if (form !== "mac") {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/${form}/delete/${record.split("+")[0]}+${record.split("+")[1]}${
              param?.split("+")[2] ? "+" + param.split("+")[2] : ""
            }+${data.sequence}`,
          "DELETE",
          null,
          headers
        );
        if (
          !res.document ||
          (res.document === "no delete" && res.model === "no delete")
        ) {
          Toast("error", "Failed to Delete.");
          return;
        }
        Toast("info", "Successfully Deleted.");

        if (URL.pathname.split("/")[1] === "temp") {
          isTreeChanged();
        } else {
          deleteChangedData();
          setIsFormChanged((prev) => !prev);
          if (form === "issue" && allData.length === 1) {
            history.push("/issue");
            deleteDataFromIndexDB(dBKey);
          } else if (form === "opm" && allData.length === 1) {
            history.push("/opm");
            deleteDataFromIndexDB(dBKey);
          } else if (form === "validation" && allData.length === 1) {
            history.push("/validation");
            deleteDataFromIndexDB(dBKey);
          } else {
            history.push(`/${form}`);
            deleteDataFromIndexDB(dBKey);
            isTreeChanged();
          }
        }
      } catch (err) {
        Toast("error", err);
      }
    } else {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/${form}?mac_name=${macParams.split("+")[0]}&mac_date=${
              macParams.split("+")[1]
            }&sequence=${data.sequence}`,
          "DELETE",
          null,
          headers
        );

        if (
          !res.state ||
          (res.state?.document === "no delete" && res.state.mac === "no delete")
        ) {
          Toast("error", "Failed to Delete.");
          return;
        } else if (Object.values(res?.state).includes("deleted")) {
          Toast("success", "Successfully Deleted.");
          deleteDataFromIndexDB(dBKey);
          deleteChangedData();
          if (res.data?.["mac data"].length === 0) {
            history.push(`/mac/${macParams?.split("+")?.[0]}/new`);
            setNodeClickedHandler(macParams?.split("+")?.[0]);
            isTreeChanged();
          } else {
            setIsFormChanged((prev) => !prev);
          }
        }
      } catch (err) {
        Toast("error", err);
      }
    }
  };

  const optionException = (tag) => {
    if (tag.fe_id === "upstream_name" || tag.fe_id === "downstream_name") {
      return modelName;
    } else if (
      tag.fe_id === "model_status" &&
      URL.pathname.split("/")[1] === "temp"
    ) {
      return status_option[0]
        .filter((item) => item[0] === "New")
        .map((item) => item[1]);
    } else if (tag.fe_id === "model_status") {
      if (isChangePage) {
        return status_option[0]
          .filter((item) => item[0] === "Change")
          .map((item) => item[1]);
      } else {
        return status_option[0]
          .filter((item) => item[0] === "Model")
          .map((item) => item[1]);
      }
    } else if (tag.fe_id === "validation_status") {
      return status_option[0]
        .filter((item) => item[0] === "Validation")
        .map((item) => item[1]);
    } else if (tag.fe_id === "issue_status") {
      return status_option[0]
        .filter((item) => item[0] === "Issue")
        .map((item) => item[1]);
    } else if (tag.fe_id === "opm_status") {
      return status_option[0]
        .filter((item) => item[0] === "OPM")
        .map((item) => item[1]);
    } else {
      return undefined;
    }
  };

  const conditionalDisabledHandler = (tag) => {
    if (isSequenceZeroExist) {
      if (data?.sequence !== Number(0)) {
        return true;
      }
    } else {
      if (data?.sequence !== Number(maxSequence)) {
        return true;
      }
    }

    if (
      form === "model" &&
      tag.fe_id === "vendor_developer" &&
      formState.inputs.vendor &&
      formState.inputs.vendor.value === "No"
    ) {
      return true;
    }

    if (
      form === "validation" &&
      tag.fe_id === "expire_date" &&
      formState.inputs.approver &&
      formState.inputs.approver.value !== "CRO Exception Approval"
    ) {
      return true;
    }

    if (
      form === "model" &&
      (tag.fe_id === "upstream_code" ||
        tag.fe_id === "downstream_code" ||
        tag.fe_id === "risk_rating" ||
        tag.fe_id === "net_rating" ||
        tag.fe_id === "approve_purpose" ||
        tag.fe_id === "certification_date" ||
        tag.fe_id === "expire_date" ||
        tag.fe_id === "approver")
    ) {
      return true;
    }

    if (form === "validation" && tag.fe_id === "next_date") {
      return true;
    }

    if (
      form === "opm" &&
      (tag.fe_id === "last_rating" ||
        tag.fe_id === "last_submission" ||
        tag.fe_id === "framework" ||
        tag.fe_id === "monitor_level")
    ) {
      return true;
    }

    if (
      tag.fe_id === "other" &&
      formState.inputs.purpose &&
      formState.inputs.purpose.value !== "Others"
    ) {
      return true;
    }

    if (form === "issue" && tag.fe_id === "auditor") {
      if (
        formState.inputs.raise &&
        !(
          formState.inputs.raise.value === "External Auditor" ||
          formState.inputs.raise.value === "External Vetters"
        )
      ) {
        return true;
      }
    }

    if (
      form === "issue" &&
      (tag.fe_id === "record" ||
        tag.fe_id === "date_closed" ||
        tag.fe_id === "approver")
    ) {
      if (
        formState.inputs.issue_status &&
        (formState.inputs.issue_status.value === "Open" ||
          formState.inputs.issue_status.value === "Past Due")
      ) {
        return true;
      }
    }

    if (form === "mac") {
      if (tag.fe_id === "mac_date") {
        return true;
      }
    }

    if (form === "issue" && tag.fe_id === "issue_id") {
      return true;
    }

    if (form === "validation" && tag.fe_id === "validation_id") {
      return true;
    }

    return false;
  };

  const formInitialValueHandler = (tag) => {
    if (form === "validation" && tag.fe_id === "next_date") {
      if (
        formState.inputs.certification_date &&
        formState.inputs.risk_rating &&
        formState.inputs.certification_date.value &&
        formState.inputs.risk_rating.value
      ) {
        const date = new Date(formState.inputs.certification_date.value);
        switch (formState.inputs.risk_rating.value) {
          case "High":
            return new Date(date.setDate(date.getDate() + 365 * 3 + 1))
              .toISOString()
              .split("T")[0];
          case "Medium":
            return new Date(date.setDate(date.getDate() + 365 * 4 + 1))
              .toISOString()
              .split("T")[0];
          case "Low":
            return new Date(date.setDate(date.getDate() + 365 * 5 + 1))
              .toISOString()
              .split("T")[0];
          default:
            return "";
        }
      }
    }

    if (tag.fe_id === "vendor_developer") {
      if (formState.inputs.vendor?.value === "No") {
        return "";
      }
    }

    if (
      tag.fe_id === "other" &&
      formState.inputs.purpose &&
      formState.inputs.purpose?.value !== "Others"
    ) {
      return "";
    }

    if (
      tag.fe_id === "expire_date" &&
      formState.inputs.approver &&
      formState.inputs.approver?.value !== "CRO Exception Approval"
    ) {
      return "";
    }

    if (form === "issue" && tag.fe_id === "auditor") {
      if (
        formState.inputs.raise &&
        !(
          formState.inputs.raise?.value === "External Auditor" ||
          formState.inputs.raise?.value === "External Vetters"
        )
      ) {
        return "";
      }
    }

    if (
      form === "issue" &&
      (tag.fe_id === "record" ||
        tag.fe_id === "approver" ||
        tag.fe_id === "date_closed") &&
      formState.inputs.issue_status &&
      (formState.inputs.issue_status.value === "Open" ||
        formState.inputs.issue_status.value === "Past Due")
    ) {
      return "";
    }

    if (tag.fe_id === "upstream_code") {
      if (
        Array.isArray(formState.inputs.upstream_name?.value) &&
        formState.inputs.upstream_name?.value.length > 0
      ) {
        return formState.inputs?.upstream_name?.value
          ?.map((model_name, i) => {
            if (modelData.filter((model) => model.name === model_name)[0]) {
              return modelData.filter((model) => model.name === model_name)[0]
                .code;
            } else {
              return (
                data?.["upstream_code"]?.[
                  data?.upstream_name?.indexOf(model_name)
                ] || ""
              );
            }
          })
          ?.toString();
      }
      if (
        Array.isArray(formState.inputs.upstream_name?.value) &&
        formState.inputs.upstream_name?.value.length === 0
      ) {
        return "";
      }
    }

    if (tag.fe_id === "downstream_code") {
      if (
        Array.isArray(formState.inputs.downstream_name?.value) &&
        formState.inputs.downstream_name?.value.length > 0
      ) {
        return formState.inputs.downstream_name?.value
          ?.map((model_name, i) => {
            if (modelData.filter((model) => model.name === model_name)[0]) {
              return modelData.filter((model) => model.name === model_name)[0]
                .code;
            } else {
              return (
                data?.["downstream_code"]?.[
                  data?.downstream_name?.indexOf(model_name)
                ] || ""
              );
            }
          })
          ?.toString();
      }
      if (
        Array.isArray(formState.inputs.downstream_name?.value) &&
        formState.inputs.downstream_name?.value.length === 0
      ) {
        return "";
      }
    }

    if (storedData && storedData[tag.fe_id]) {
      return storedData[tag.fe_id];
    }

    if (data?.[`${tag.fe_id}`]) {
      return data[`${tag.fe_id}`];
    }

    if (
      form === "validation" &&
      (tag.fe_id === "recommendation" || tag.fe_id === "escalation_num")
    ) {
      return data[`${tag.fe_id}`] ? data[`${tag.fe_id}`] : 0;
    }

    if (form === "validation" && tag.fe_id === "validation_id") {
      return "ATV";
    }
  };

  const formValidatorHandler = (tag) => {
    if (tag.fe_id === "validation_id") {
      return [VALIDATOR_VALIDATION_ID()];
    }

    if (form === "issue" && tag.fe_id === "issue_id") {
      return [VALIDATOR_VALIDATION_ACTIVITY_ID()];
    }
    return [];
  };

  const formErrorTextHandler = (tag) => {
    if (
      (tag.fe_id === "risk_rating" || tag.fe_id === "net_rating") &&
      formState.inputs.net_rating &&
      formState.inputs.risk_rating &&
      ratingOption[formState.inputs.net_rating.value] >=
        ratingOption[formState.inputs.risk_rating.value]
    ) {
      return "Net rating should be lower than or equal to risk rating";
    }

    if (tag.fe_id === "validation_id") {
      return "MRM Validation ID should be in the format of ATV0001";
    }

    if (form === "issue" && tag.fe_id === "issue_id") {
      return "Model Issue ID should be in format of 'OSFI00001 or MRM00001'";
    }
    return "";
  };

  const formOnBlurHandler = (tag) => {
    if (
      tag.fe_id === "validation_id" ||
      tag.fe_id === "risk_rating" ||
      tag.fe_id === "net_rating" ||
      tag.fe_id === "issue_id"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const changePageVersionId = () => {
    if (storedData && storedData.version) {
      return storedData.version;
    }
    if (isChangePage && latestVersion && latestVersion[0]) {
      let versionId_change;
      const versionNum = (Number(latestVersion[0]?.slice(-3)) + 1).toString();
      if (versionNum.length === 1) {
        versionId_change = "00" + versionNum;
      } else if (versionNum.length === 2) {
        versionId_change = "0" + versionNum;
      } else if (versionNum.length === 3) {
        versionId_change = versionNum;
      }
      return latestVersion[0]?.slice(0, -3) + versionId_change;
    } else {
      return data.version;
    }
  };

  const PopUpModalData = tableData;

  const isDataChanged = () => {
    if (URL.pathname?.split("/")?.[1] === "temp") {
      const tempTag = tag.filter(
        (i) =>
          i.fe_isrender === true &&
          i.fe_id !== "upstream_code" &&
          i.fe_id !== "downstream_code"
      );
      for (let i = 0; i < tempTag.length; i++) {
        if (
          !isTwoJsonValueEqual(
            data?.[tempTag[i].fe_id],
            formState.inputs?.[tempTag[i].fe_id]?.value
          )
        ) {
          // console.log("data changed");
          // console.log("Label:", tempTag[i].fe_label);
          // console.log("initial:", data?.[tempTag[i].fe_id]);
          // console.log("current:", formState.inputs?.[tempTag[i].fe_id]?.value);
          return true;
        }
      }
    } else {
      for (let i = 0; i < tag2.length; i++) {
        if (
          tag2[i].fe_id !== "upstream_code" &&
          tag2[i].fe_id !== "downstream_code" &&
          !isTwoJsonValueEqual(
            data?.[tag2[i].fe_id],
            formState.inputs?.[tag2[i].fe_id]?.value
          )
        ) {
          // console.log("data changed");
          // console.log("Label:", tag2[i].fe_label);
          // console.log("initial:", data?.[tag2[i].fe_id]);
          // console.log("current:", formState.inputs?.[tag2[i].fe_id]?.value);
          return true;
        }
      }
    }

    if (newAccessLog?.length > 0) {
      //console.log("newAccessLog exist");
      return true;
    }
    if (newDocument?.length > 0) {
      // console.log("files exist");
      return true;
    }
    if (form === "validation") {
      for (let i = 0; i < tag3.length; i++) {
        if (
          !isTwoJsonValueEqual(
            data?.[tag3[i].fe_id],
            formState.inputs?.[tag3[i].fe_id]?.value,
            "validation"
          )
        ) {
          // console.log("assumption data changed");
          // console.log("id:", tag3[i].fe_id);
          return true;
        }
      }
    }
    if (form === "opm") {
      if (
        !isTwoJsonValueEqual(
          quantitativeMetric,
          formState.inputs?.quantitative_metric?.value,
          "quantitative"
        ) ||
        !isTwoJsonValueEqual(
          qualitativeMetric,
          formState.inputs?.qualitative_metric?.value,
          "qualitative"
        )
      ) {
        // console.log("metric data changed");
        // console.log(
        //   qualitativeMetric,
        //   formState.inputs?.quantitative_metric?.value
        // );
        // console.log(
        //   quantitativeMetric,
        //   formState.inputs?.qualitative_metric?.value
        // );
        return true;
      }
    }
    if (form === "mac") {
      const macTag = [
        { id: "attendance", label: "Attendances" },
        { id: "memo", label: "MAC Purpose" },
        { id: "state", label: "Actions" },
      ];
      for (let i = 0; i < macTag.length; i++) {
        if (
          !isTwoJsonValueEqual(
            data?.[macTag[i].id],
            formState.inputs?.[macTag[i].id]?.value,
            macTag[i].id
          )
        ) {
          // console.log("mac data changed");
          // console.log("Label:", macTag[i].label);
          return true;
        }
      }
    }
    return false;
  };

  const deleteChangedData = () => {
    if ((form === "validation" || form === "issue") && param.split("+")[2]) {
      isPageDataNotChangedHandler(form, param.split("+")[2]);
    } else {
      if (form === "model") {
        if (isChangePage) {
          isPageDataNotChangedHandler("change", 0);
        } else {
          isPageDataNotChangedHandler("model", 0);
        }
      }
      if (form === "opm") {
        isPageDataNotChangedHandler(form, param.split("+")[1]);
      }
      if (form === "mac") {
        isPageDataNotChangedHandler(form, 0);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      //console.log("comparing...");
      if (
        !isLoading &&
        ((data?.sequence === maxSequence && !isSequenceZeroExist) ||
          data?.sequence === Number(0))
      ) {
        //console.log("comparing run ...");
        if (isDataChanged()) {
          if (
            (form === "validation" || form === "issue") &&
            param.split("+")[2]
          ) {
            isPageDataChangedHandler(form, param.split("+")[2]);
          } else {
            if (form === "model") {
              if (isChangePage) {
                isPageDataChangedHandler("change", 0);
              } else {
                isPageDataChangedHandler("model", 0);
              }
            }
            if (form === "opm") {
              isPageDataChangedHandler(form, param.split("+")[1]);
            }
            if (form === "mac") {
              isPageDataChangedHandler(form, 0);
            }
          }
        } else {
          deleteChangedData();
        }
      }
    }, 10);

    // Cleanup function
    return () => {
      clearTimeout(timer);
    };
  }, [formState.inputs, files, oldDocument, newDocument, newAccessLog]);

  useEffect(() => {
    return history.listen((location) => {
      const sampleJSONdata = {};
      form === "model" &&
        (sampleJSONdata["code"] = formState.inputs.code?.value || "");
      form === "model" &&
        (sampleJSONdata["version"] = formState.inputs.version?.value || "");
      form === "model" &&
        (sampleJSONdata["osfi"] = formState.inputs.osfi?.value || "");
      form === "in use" &&
        (sampleJSONdata["topic"] = formState.inputs.topic?.value || "");
      form === "in use" &&
        (sampleJSONdata["version"] = formState.inputs.version?.value || "");
      form === "opm" && (sampleJSONdata["metric"] = metric || []);
      sampleJSONdata["file"] = files || [];
      sampleJSONdata["oldDocument"] = oldDocument || [];
      sampleJSONdata["newDocument"] = newDocument || [];
      sampleJSONdata["traceList"] = traceList || [];
      sampleJSONdata["newAccessLog"] = newAccessLog || [];

      tag.forEach((item) => {
        sampleJSONdata[item.fe_id] = formState.inputs[item.fe_id]?.value
          ? formState.inputs[item.fe_id]?.value
          : "";
      });

      form === "validation" &&
        tag3.forEach((item) => {
          sampleJSONdata[item.fe_id] = formState.inputs[item.fe_id]?.value
            ? formState.inputs[item.fe_id]?.value
            : [];
        });

      if (
        form === "opm" &&
        Array.isArray(quantitativeMetric) &&
        Array.isArray(qualitativeMetric)
      ) {
        sampleJSONdata["quantitativeMetric"] = quantitativeMetric.map(
          (item, i) => {
            return {
              ...item,
              value1:
                formState.inputs.quantitative_metric?.value?.[i]?.value1 ||
                Number(0),
              value2:
                formState.inputs.quantitative_metric?.value?.[i]?.value2 ||
                Number(0),
              value3:
                formState.inputs.quantitative_metric?.value?.[i]?.value3 ||
                Number(0),
              value4:
                formState.inputs.quantitative_metric?.value?.[i]?.value4 ||
                Number(0),
            };
          }
        );
        sampleJSONdata["qualitativeMetric"] = qualitativeMetric.map(
          (item, i) => {
            return {
              ...item,
              attestation1:
                formState.inputs.qualitative_metric?.value?.[i]?.attestation1 ||
                "",
              attestation2:
                formState.inputs.qualitative_metric?.value?.[i]?.attestation2 ||
                "",
              attestation3:
                formState.inputs.qualitative_metric?.value?.[i]?.attestation3 ||
                "",
              attestation4:
                formState.inputs.qualitative_metric?.value?.[i]?.attestation4 ||
                "",
            };
          }
        );
      }

      if (form === "mac") {
        sampleJSONdata["attendance"] =
          formState.inputs?.attendance?.value || [];
        sampleJSONdata["memo"] = formState.inputs?.memo?.value || [];
        sampleJSONdata["state"] = formState.inputs?.state?.value || [];
        sampleJSONdata["mac_comment"] =
          formState.inputs?.mac_comment?.value || "";
      }

      saveDataInIndexDB(sampleJSONdata, dBKey);
      // getDataFromDB(dBKey);
    });
  }, [history, sequence, formState.inputs]);

  const isFileDisabled = () => {
    if (isSequenceZeroExist) {
      if (data?.sequence !== Number(0)) {
        return true;
      }
    } else {
      if (data?.sequence !== Number(maxSequence)) {
        return true;
      }
    }
    return false;
  };

  const element = (
    <div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              width: "50%",
              maxWidth: "100%",
              minWidth: "300px",
              height: "90%",
              padding: "1rem 2rem 1rem 2rem",
            },
          }}
        >
          <DialogTitle>
            <PopUpTable tableData={PopUpModalData} isNew={false} />
          </DialogTitle>
          <DialogActions>
            <Button onClick={formUpdateHandler}>Confirm</Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          height: "50px",
          position: "relative",
          zIndex: "100",
        }}
      >
        <ListTab param={param} form={form} />
      </div>
      <form style={{ margin: "0 8rem 2rem 8rem" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={0.5} columnSpacing={5}>
            {(isLoading || !data || !tag) && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            {form === "model" && (
              <Grid item xs={12} md={6} lg={4}>
                <InputNew
                  id="code"
                  type="text"
                  label="Model ID(*)"
                  element="input"
                  onInput={inputHandler}
                  onBlur
                  initialValue={storedData ? storedData.code : data.code}
                  placeholder="eg. ERM.0.01.01.V02"
                  errorText="Model ID should be in the format of ERM.0.01.01.V02"
                  validators={[VALIDATOR_CODE()]}
                  disabled={!isChangePage}
                />
              </Grid>
            )}

            {form === "model" && (
              <Grid item xs={12} md={6} lg={4}>
                <InputNew
                  id="version"
                  type="text"
                  label="Full Version ID(*)"
                  element="input"
                  initialValue={changePageVersionId()}
                  onInput={inputHandler}
                  validators={[VALIDATOR_MODEL_VERSION_ID()]}
                  errorText="Full Version ID should be in the format of V01CH000"
                  placeholder="eg. V01CH000"
                  disabled={!isChangePage}
                  onBlur
                />
              </Grid>
            )}
            {form === "model" && (
              <Grid item xs={12} md={6} lg={4}>
                <InputNew
                  id="osfi"
                  type="text"
                  label="OSFI ID(*)"
                  element="input"
                  initialValue={modelOsfi}
                  onInput={inputHandler}
                  disabled
                />
              </Grid>
            )}
            {form === "in use" && (
              <Grid item xs={12} md={6} lg={6}>
                <InputNew
                  id="topic"
                  type="text"
                  label="Topic"
                  element="input"
                  onInput={inputHandler}
                  initialValue={data.topic}
                  validators={[]}
                  onRecord={() => {}}
                  disabled
                />
              </Grid>
            )}
            {form === "in use" && (
              <Grid item xs={12} md={6} lg={6}>
                <InputNew
                  id="version"
                  type="text"
                  label="Target Submission Date"
                  element="input"
                  onInput={inputHandler}
                  initialValue={data.version}
                  validators={[]}
                  onRecord={() => {}}
                  disabled
                />
              </Grid>
            )}
            {form === "opm" &&
              opmTag
                .sort((a, b) => a.fe_order - b.fe_order)
                .map((tag, i) => (
                  <Grid
                    item
                    xs={12}
                    md={tag.fe_grid === 12 ? 12 : 6}
                    lg={tag.fe_grid}
                    key={i}
                  >
                    <InputNew
                      id={tag.fe_id}
                      type={tag.fe_type}
                      label={tag.fe_label}
                      element={tag.fe_element}
                      onInput={inputHandler}
                      initialValue={formInitialValueHandler(tag)}
                      initialValid={true}
                      validators={formValidatorHandler(tag)}
                      errorText={formErrorTextHandler(tag)}
                      onBlur={formOnBlurHandler(tag)}
                      origin_options={tag}
                      clean_options={optionException(tag)}
                      traceList={traceList}
                      onRecord={formStatusChange}
                      disabled={conditionalDisabledHandler(tag)}
                      options={
                        userGroup
                          ? userGroup.map((user) => ({
                              label: user,
                              value: user,
                            }))
                          : []
                      }
                      setScrollTop={setScrollTop}
                      pageElement={pageElement}
                    />
                  </Grid>
                ))}
            {form !== "opm" &&
              tag
                .filter(
                  (item) =>
                    item.fe_id !== "version" &&
                    item.fe_isrender === true &&
                    item.fe_id !== "mac_comment"
                )
                .sort((a, b) => a.fe_order - b.fe_order)
                .map((tag, i) => (
                  <Grid
                    item
                    xs={12}
                    md={tag.fe_grid === 12 ? 12 : 6}
                    lg={tag.fe_grid}
                    key={i}
                  >
                    <InputNew
                      id={tag.fe_id}
                      type={tag.fe_type}
                      label={tag.fe_label}
                      element={tag.fe_element}
                      onInput={inputHandler}
                      initialValue={formInitialValueHandler(tag)}
                      initialValid={true}
                      validators={formValidatorHandler(tag)}
                      errorText={formErrorTextHandler(tag)}
                      onBlur={formOnBlurHandler(tag)}
                      origin_options={tag}
                      clean_options={optionException(tag)}
                      traceList={traceList}
                      onRecord={formStatusChange}
                      disabled={conditionalDisabledHandler(tag)}
                      options={
                        userGroup
                          ? userGroup.map((user) => ({
                              label: user,
                              value: user,
                            }))
                          : []
                      }
                      setScrollTop={setScrollTop}
                      pageElement={pageElement}
                    />
                  </Grid>
                ))}

            {form === "validation" &&
              tag3.map((tag, i) => (
                <Grid
                  item
                  xs={12}
                  md={tag.fe_grid === 12 ? 12 : 6}
                  lg={tag.fe_grid}
                  key={i}
                >
                  <InputNew
                    id={tag.fe_id}
                    type={tag.fe_type}
                    label={tag.fe_label}
                    element={tag.fe_element}
                    onInput={inputHandler}
                    initialValue={formInitialValueHandler(tag)}
                    clean_options={optionException(tag)}
                    traceList={traceList}
                    onRecord={formStatusChange}
                    tableData={[]}
                    form={table}
                    indicator={record.split("+")[0]}
                    version={record.split("+")[1]}
                    sequence={data.sequence}
                    userId={display_name}
                    validationId={data?.validation_id}
                    disabled={conditionalDisabledHandler(tag)}
                    setScrollTop={setScrollTop}
                    pageElement={pageElement}
                  />
                </Grid>
              ))}

            {form === "opm" && (
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: "50%" }}>
                    <div style={{ padding: "0.8rem", marginBottom: "2rem" }}>
                      <InputNew
                        id="quantitative_metric"
                        label="Model Performance Metric (Quantitative Result)"
                        element="opmTable"
                        onInput={inputHandler}
                        initialValue={quantitativeMetric}
                        isAddButtonNotShow
                        colorReference={quantitativeColor}
                        isFourColsShow={quantitativeMetric
                          .map((i) => i.isFourColsShow)
                          .includes(true)}
                        disabled={conditionalDisabledHandler(tag)}
                      />
                    </div>
                    <div style={{ padding: "0.8rem", marginBottom: "2rem" }}>
                      <InputNew
                        id="qualitative_metric"
                        label="Assessment (Qualitative Result)"
                        element="opmTable"
                        onInput={inputHandler}
                        initialValue={qualitativeMetric}
                        isAddButtonNotShow
                        colorReference={qualitativeColor}
                        qualitativeOption={qualitativeOption}
                        isFourColsShow={qualitativeMetric
                          .map((i) => i.isFourColsShow)
                          .includes(true)}
                        disabled={conditionalDisabledHandler(tag)}
                      />
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    {isCalculating && (
                      <h5
                        style={{ marginBottom: "200px", textAlign: "center" }}
                      >
                        <CircularProgress />
                      </h5>
                    )}
                    {!isCalculating && typeof score === "number" && (
                      <div>
                        <h5
                          style={{
                            paddingBottom: "1.5rem",
                            textAlign: "center",
                          }}
                        >
                          Score: {score?.toFixed(2)}
                        </h5>
                        <div className="metric-score">
                          <OpmScore
                            value={score?.toFixed(2)}
                            gaugeData={gaugeDataSaver}
                          />
                        </div>
                      </div>
                    )}
                    {!isCalculating && typeof score !== "number" && (
                      <div>
                        <h5
                          style={{
                            paddingBottom: "1.5rem",
                            textAlign: "center",
                          }}
                        >
                          Score: {0}
                        </h5>
                        <div className="metric-score">
                          <OpmScore value={0} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            )}

            {form === "mac" &&
              [
                { id: "attendance", label: "Attendances" },
                { id: "memo", label: "MAC Purpose" },
                { id: "state", label: "Actions" },
              ].map((i) => (
                <Grid item xs={12}>
                  <div style={{ padding: "0.8rem", margin: "1.2rem 0 0 0" }}>
                    <InputNew
                      id={i.id}
                      label={i.label}
                      element="macTable"
                      onInput={inputHandler}
                      initialValue={
                        storedData?.[i.id] ? storedData?.[i.id] : data[i.id]
                      }
                      modelIdGroup={modelIdGroup}
                      userGroup={userGroup}
                      mac_attendance={attendanceData || []}
                      disabled={conditionalDisabledHandler(tag)}
                    />
                  </div>
                </Grid>
              ))}

            {form === "mac" && (
              <Grid item xs={12}>
                <InputNew
                  id="mac_comment"
                  label="Comment"
                  element="textEditor"
                  onInput={inputHandler}
                  initialValid={true}
                  initialValue={
                    storedData?.mac_comment
                      ? storedData?.mac_comment
                      : data.mac_comment
                  }
                  onRecord={() => {}}
                  disabled={conditionalDisabledHandler(tag)}
                  setScrollTop={setScrollTop}
                  pageElement={pageElement}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <div style={{ padding: "3rem 0.8rem 0 0.8rem" }}>
                <FileLoad
                  uploadHandler={uploadFileHandler}
                  removeHandler={removeFileHandler}
                  oldDocument={oldDocument}
                  newDocument={newDocument}
                  form={form}
                  onInput={inputHandler}
                  fileChangeHandler={fileChangeHandler}
                  record={record}
                  issueId={issueId}
                  validationId={data?.validation_id}
                  isPolicy={policyTopic ? true : false}
                  isModelChange={modelChangeTopic ? true : false}
                  topic={data?.topic}
                  fileOption={form !== "mac" ? fileOption : undefined}
                  macFileType={form === "mac" ? macFileType : undefined}
                  token={token}
                  disabled={isFileDisabled()}
                  fileDescriptionArr={storedData?.fileDescriptionArr}
                />
              </div>
            </Grid>
            {(isLoading || !data || !tag) && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                {URL.pathname.split("/")[1] !== "temp" && (
                  <Button
                    onClick={() => handleClickOpen("publish")}
                    disabled={isButtonDisabled()}
                    variant="outlined"
                    className="editButton"
                  >
                    Submit
                  </Button>
                )}

                {
                  <Button
                    onClick={() => handleClickOpen("save")}
                    disabled={isButtonDisabled()}
                    variant="outlined"
                    className="editButton"
                  >
                    Save
                  </Button>
                }

                {
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={tempDeleteHandler}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      variant="outlined"
                      className="editButton"
                      disabled={deleteButtonDisabledHandler()}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                }
              </div>
              {/*<Button
                    onClick={checkHandler}               
                    variant="outlined"
                    className="editButton"
                  >
                    Check
              </Button>*/}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Pagination
                showFirstButton
                showLastButton
                count={pageCount}
                page={sequence}
                onChange={(event, value) => {
                  setSequence(value);
                  history.push(`/${form}/${param}+${value}`);
                }}
                boundaryCount={10}
                renderItem={(item) => {
                  let pageColor = {};
                  if (
                    item.type === "page" &&
                    item.page === sequence &&
                    data.sequence === 0
                  ) {
                    pageColor = { color: "primary", backgroundColor: "red" };
                  }
                  if (form === "validation") {
                    const outputArray = [];
                    const seenValues = new Map();

                    allData.forEach((obj) => {
                      const numericValue = parseInt(obj.validation_id.slice(3));
                      const currentValue = seenValues.get(numericValue);

                      if (!currentValue || obj.sequence > currentValue) {
                        seenValues.set(numericValue, obj.sequence);
                      }
                    });

                    seenValues.forEach((value) => {
                      outputArray.push(value);
                    });

                    if (
                      item.type === "page" &&
                      outputArray.includes(item.page)
                    ) {
                      pageColor = {
                        color: "primary",
                        backgroundColor: "Green",
                      };
                    }
                  }
                  return (
                    <PaginationItem
                      {...item}
                      components={{
                        first: (props) => "Oldest",
                        last: (props) => "Newest",
                      }}
                      sx={pageColor}
                      color={
                        item.type === "page" &&
                        item.page === sequence &&
                        data.sequence === 0
                          ? "error"
                          : "standard"
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );

  if (isLoading || !data || !tag || isSequenceChanged) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10%",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return <Fragment>{element}</Fragment>;
};

export default TemplatePage;
