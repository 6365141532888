import { Box, styled } from "@mui/material";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import Image from "../../../../../assets/LogoInven2.png";

const ListWrapper = styled(Box)(({ theme }) => ``);

function HeaderMenu() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "block",
        }}
      >
        <a href="/model" style={{ textDecoration: "none" }}>
          <img src={Image} alt="logo" style={{ width: "12rem" }} />
        </a>
      </Box>
    </>
  );
}

export default HeaderMenu;
