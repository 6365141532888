import React, { useState } from "react";

import { useMsal } from "@azure/msal-react";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { loginRequest } from "../../authConfig";

import LogoImage from "../../assets/Logo3.png";

import classes from "./LoginMock.module.css";

const LoginMock = (props) => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    instance.loginRedirect(loginRequest).catch((e) => {
    });
  };

  return (
    <div>

    <br />
    <br />

      <form className={classes.form}>
        <img src={LogoImage} alt="LogoImage" width="180" />
        <br />
        <h3 style={{ color: "#1582C7" }}>Model Governance System</h3>
        <div className="mb-3"></div>
        <br />
        <div className="d-grid">
          <MicrosoftLoginButton
            onClick={() => {
              handleLogin();
            }}
          >
            Log in with Azure AD
          </MicrosoftLoginButton>
        </div>
  
      </form>
    </div>
  );
};

export default LoginMock;
