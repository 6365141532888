import { useState, useEffect, Fragment, useContext } from "react";
import dayjs from "dayjs";
import InputNew from "../../components/Content/FormElements/InputNew";
import { UserContext } from "../../context/user-context";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import {
  Button,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Grid,
} from "@mui/material";

import "./Notification.css";
import Toast from "../../components/Utils/Toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "100%",
  bgcolor: "background.paper",
  border: "1px solid #b5b4b4",
  borderRadius: "5px",
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  p: 4,
  overflow: "auto",
};

const Notification = (props) => {
  const { error, sendRequest, clearError } = useHttpClient();
  const { user, tabNameOutToInHandler, tabNameInToOutHandler } =
    useContext(UserContext);
  const [formState, inputHandler] = useForm(
    { form_notification: { value: "" }, form_notification_new: { value: "" } },
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [statusOption, setStatusOption] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [notification, setNotification] = useState([]);
  const [notificationSelected, setNotificationSelected] = useState([]);
  const [frequencyOption, setFrequencyOption] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [currentForm, setCurrentForm] = useState({
    "due descriptor": [],
    operation: [],
  });
  const [isChanged, setIsChanged] = useState(false);

  const { display_name } = user || {};

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/notification`
        );
        setIsLoading(false);
        setNotification(response.notification);
        setOptions(response.option);
        setFrequencyOption(response.frequency);
        setGroupList(response.group);
      } catch (err) {
        console.warn(err);
      }
    };
    fetchData();
  }, [sendRequest, isChanged]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleSelectionClick = (event, index) => {
    setSelectedIndex(index);
    setNotificationSelected(
      notification.filter(
        (item) =>
          item.form === index.split(" -- ")[0] &&
          item.operation === index.split(" -- ")[1]
      )[0]
    );
  };

  useEffect(() => {
    options &&
      setCurrentForm(
        options[
          `${tabNameInToOutHandler(formState.inputs.form_notification.value)}`
        ] || {
          "due descriptor": [],
          operation: [],
        }
      );
  }, [
    formState.inputs.form_notification?.value,
    options,
    notificationSelected,
  ]);

  useEffect(() => {
    setCurrentForm(
      options?.[
        `${tabNameInToOutHandler(
          formState.inputs.form_notification_new?.value
        )}`
      ] || {
        "due descriptor": [],
        operation: [],
      }
    );
  }, [formState.inputs.form_notification_new?.value, options]);

  const notificationRequiredFieldCheck = () => {
    if (
      !formState.inputs?.form_notification_new?.value ||
      !formState.inputs?.status_notification_new?.value ||
      !formState.inputs?.due_notification_new?.value ||
      formState.inputs?.form_notification_new?.value === "" ||
      formState.inputs?.status_notification_new?.value === "" ||
      formState.inputs?.due_notification_new?.value === ""
    ) {
      Toast("error", "Please fill in required fields.");
      return false;
    }

    return true;
  };

  const notificationOverrideUpdateCheck = () => {
    if (
      formState.inputs.due_notification?.value !== "NULL" &&
      formState.inputs.due_override?.value !== "NULL" &&
      formState.inputs.due_notification?.value ===
        formState.inputs.due_override?.value
    ) {
      Toast("error", "Due notification and Due override cannot be the same.");
      return false;
    }
    return true;
  };

  const notificationOverrideNewCheck = () => {
    if (
      formState.inputs.due_notification_new?.value !== "NULL" &&
      formState.inputs.due_override_new?.value !== "NULL" &&
      formState.inputs.due_notification_new?.value ===
        formState.inputs.due_override_new?.value
    ) {
      Toast("error", "Due notification and Due override cannot be the same.");
      return false;
    }
    return true;
  };

  const notificationEmptyCheck = () => {
    if (!notificationSelected || notificationSelected.length === 0) {
      Toast("error", "Please select a notification.");
      return false;
    }
    return true;
  };

  const addNewNotificationHandler = async (e) => {
    e.preventDefault();

    if (!notificationRequiredFieldCheck()) {
      return;
    }

    if (!notificationOverrideNewCheck()) {
      return;
    }

    if (
      notification.find(
        (item) =>
          item.form ===
            tabNameInToOutHandler(
              formState.inputs.form_notification_new?.value
            ) &&
          item.operation === formState.inputs.status_notification_new?.value
      )
    ) {
      Toast("error", "Task and Status exists.");
      return;
    }

    try {
      const body = {
        form:
          tabNameInToOutHandler(formState.inputs.form_notification_new.value) ||
          "",
        operation: formState.inputs.status_notification_new.value || "",
        due_descriptor:
          currentForm["due descriptor"] &&
          formState.inputs.due_notification_new.value !== "NULL" &&
          currentForm["due descriptor"].filter(
            (i) => i[0] === formState.inputs.due_notification_new?.value
          )[0]
            ? currentForm["due descriptor"].filter(
                (i) => i[0] === formState.inputs.due_notification_new?.value
              )[0][1]
            : "",
        due_override:
          currentForm["due descriptor"] &&
          formState.inputs.due_override_new?.value !== "NULL" &&
          currentForm["due descriptor"].filter(
            (i) => i[0] === formState.inputs.due_override_new?.value
          )[0]
            ? currentForm["due descriptor"].filter(
                (i) => i[0] === formState.inputs.due_override_new?.value
              )[0]?.[1]
            : "",

        notification_day: formState.inputs.day_notification_new.value || "",
        during_frequency: formState.inputs.during_frequency_new.value || "",
        after_frequency: formState.inputs.after_frequency_new.value || "",
        //from: formState.inputs.from_notification_new.value || "",
        to: formState.inputs.to_notification_new.value || "",
        copy: formState.inputs.cc_notification_new.value || "",
        subject: formState.inputs.subject_notification_new.value || "",
        content: formState.inputs.content_notification_new.value || "",
        update_time: currentDate || "",
        user_id: display_name || "",
      };

      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/notification`,
        "POST",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      if (!res.notification) {
        alert(res, 3);
        return;
      } else {
        alert("Update success.", 3);
        setModalOpen(false);
      }
      setIsChanged((prev) => !prev);
    } catch (err) {}
  };

  const updateNotificationHandler = async (e) => {
    e.preventDefault();
    if (!notificationEmptyCheck()) return;
    if (!notificationOverrideUpdateCheck()) return;
    try {
      const body = {
        form:
          tabNameInToOutHandler(formState.inputs.form_notification.value) || "",
        operation: formState.inputs.status_notification.value || "",
        due_descriptor:
          currentForm["due descriptor"] &&
          formState.inputs.due_notification.value !== "NULL" &&
          currentForm["due descriptor"].filter(
            (i) => i[0] === formState.inputs.due_notification?.value
          )[0]
            ? currentForm["due descriptor"].filter(
                (i) => i[0] === formState.inputs.due_notification?.value
              )[0][1]
            : "",
        due_override:
          currentForm["due descriptor"] &&
          formState.inputs.due_override.value !== "NULL" &&
          currentForm["due descriptor"].filter(
            (i) => i[0] === formState.inputs.due_override?.value
          )[0]
            ? currentForm["due descriptor"].filter(
                (i) => i[0] === formState.inputs.due_override?.value
              )[0][1]
            : "",
        notification_day: formState.inputs.day_notification.value || "",
        during_frequency: formState.inputs.during_frequency.value || "",
        after_frequency: formState.inputs.after_frequency.value || "",
        //from: formState.inputs.from_notification.value || "",
        to: formState.inputs.to_notification.value || "",
        copy: formState.inputs.cc_notification.value || "",
        subject: formState.inputs.subject_notification.value || "",
        content: formState.inputs.content_notification.value || "",
        update_time: currentDate || "",
        user_id: display_name || "",
      };
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/notification`,
        "PUT",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      if (!res.notification) {
        alert(res, 3);
        return;
      } else {
        alert("Update success.", 3);
        setModalOpen(false);
      }
      setSelectedIndex("");
      setNotificationSelected([]);
      setIsChanged((prev) => !prev);
    } catch (err) {}
  };

  const deleteNotificationHandler = async (e) => {
    e.preventDefault();
    if (!notificationEmptyCheck()) return;
    try {
      const body = {
        form: tabNameInToOutHandler(notificationSelected.form),
        operation: notificationSelected.operation,
      };
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/notification`,
        "DELETE",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      if (!res.notification) {
        alert(res, 3);
        return;
      } else {
        alert("Delete success.", 3);
        setModalOpen(false);
      }
      setSelectedIndex("");
      setNotificationSelected([]);
      setIsChanged((prev) => !prev);
    } catch (err) {
      alert(err, 3);
    }
  };

  if (!notificationSelected) {
    setNotificationSelected([]);
  }

  if (isLoading || !notification || !Array.isArray(notification)) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  let element = notificationSelected ? (
    <div style={{ margin: "0 3rem 2rem 3rem" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={0.5} columnSpacing={5}>
          <Grid item xs={6}>
            <InputNew
              id="form_notification"
              label="Task(*)"
              element="select"
              validators={[]}
              onInput={inputHandler}
              initialValue={tabNameOutToInHandler(notificationSelected.form)}
              onBlur
              clean_options={
                options
                  ? Object.keys(options).map((opt) =>
                      tabNameOutToInHandler(opt)
                    )
                  : []
              }
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <InputNew
              id="status_notification"
              label="Status(*)"
              element="select"
              initialValue={
                notificationSelected && notificationSelected.operation
              }
              validators={[]}
              onInput={inputHandler}
              onBlur
              clean_options={currentForm && currentForm.operation}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <InputNew
              id="due_notification"
              label="Due Reference(*)"
              element="select"
              validators={[]}
              onInput={inputHandler}
              initialValue={
                notificationSelected.due_descriptor !== ""
                  ? notificationSelected.due_descriptor &&
                    currentForm?.["due descriptor"].filter(
                      (i) => i[1] === notificationSelected.due_descriptor
                    )[0] &&
                    currentForm["due descriptor"].filter(
                      (i) => i[1] === notificationSelected.due_descriptor
                    )[0]?.[0]
                  : "NULL"
              }
              onBlur
              clean_options={
                currentForm && currentForm["due descriptor"][0]
                  ? [...currentForm["due descriptor"].map((i) => i[0]), "NULL"]
                  : []
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputNew
              id="due_override"
              label="Due Override"
              element="select"
              validators={[]}
              onInput={inputHandler}
              initialValue={
                formState.inputs.due_notification?.value === "NULL"
                  ? "NULL"
                  : notificationSelected.due_override &&
                    currentForm?.["due descriptor"].filter(
                      (i) => i[1] === notificationSelected.due_override
                    )[0] &&
                    currentForm["due descriptor"].filter(
                      (i) => i[1] === notificationSelected.due_override
                    )?.[0]?.[0]
              }
              onBlur
              clean_options={
                Array.isArray(currentForm?.["due descriptor"])
                  ? [
                      ...currentForm["due descriptor"].map((i) => i[0]),
                      "NULL",
                    ].filter(
                      (i) => i !== formState.inputs?.due_notification?.value
                    )
                  : []
              }
              disabled={formState.inputs.due_notification?.value === "NULL"}
            />
          </Grid>
          <Grid item xs={4}>
            <InputNew
              id="day_notification"
              type="number"
              label="Notification start before due date(days)"
              element="input"
              initialValue={
                (formState.inputs.due_notification
                  ? formState.inputs.due_notification.value
                  : "") === "NULL"
                  ? ""
                  : notificationSelected.notification_day
              }
              validators={[]}
              onInput={inputHandler}
              onBlur
              disabled={
                (formState.inputs.due_notification
                  ? formState.inputs.due_notification.value
                  : "") === "NULL"
              }
            />
          </Grid>

          <Grid item xs={6}>
            <InputNew
              id="during_frequency"
              label="Frequency before due date"
              element="select"
              validators={[]}
              initialValue={
                (formState.inputs.due_notification
                  ? formState.inputs.due_notification.value
                  : "") === "NULL"
                  ? ""
                  : notificationSelected.during_frequency
              }
              onInput={inputHandler}
              onBlur
              clean_options={frequencyOption}
              disabled={
                (formState.inputs.due_notification
                  ? formState.inputs.due_notification.value
                  : "") === "NULL"
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputNew
              id="after_frequency"
              label="Frequency after due date:"
              element="select"
              validators={[]}
              initialValue={
                (formState.inputs.due_notification
                  ? formState.inputs.due_notification.value
                  : "") === "NULL"
                  ? ""
                  : notificationSelected.after_frequency
              }
              onInput={inputHandler}
              onBlur
              clean_options={frequencyOption}
              disabled={
                (formState.inputs.due_notification
                  ? formState.inputs.due_notification.value
                  : "") === "NULL"
              }
            />
          </Grid>
          {/*<Grid item xs={12}>
            <InputNew
              id="from_notification"
              label="From:"
              element="input"
              validators={[]}
              onInput={inputHandler}
              initialValue={notificationSelected.from}
              onBlur
            />
            </Grid>*/}
          <Grid item xs={12}>
            <InputNew
              id="to_notification"
              label="To"
              element="autoComplete"
              validators={[]}
              onInput={inputHandler}
              initialValue={notificationSelected.to}
              clean_options={groupList}
              onBlur
            />
          </Grid>
          <Grid item xs={12}>
            <InputNew
              id="cc_notification"
              label="CC"
              element="autoComplete"
              validators={[]}
              onInput={inputHandler}
              initialValue={notificationSelected.copy}
              clean_options={groupList}
              onBlur
            />
          </Grid>
          <Grid item xs={12}>
            <InputNew
              id="subject_notification"
              label="Subject"
              element="input"
              validators={[]}
              onInput={inputHandler}
              initialValue={notificationSelected.subject}
              onBlur
            />
          </Grid>
          <Grid item xs={12}>
            <InputNew
              id="content_notification"
              label="Email Content"
              element="textEditor"
              initialValue={notificationSelected.content}
              validators={[]}
              rows={5}
              onInput={inputHandler}
              setScrollTop={props.setScrollTop}
              pageElement={props.pageElement}
              onBlur
              isAutoSize
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  ) : (
    <div></div>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={modalStyle}>
            <Grid container rowSpacing={0} columnSpacing={0}>
              <Grid item xs={6}>
                <InputNew
                  id="form_notification_new"
                  label="Task(*)"
                  element="select"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                  clean_options={
                    options
                      ? Object.keys(options).map((opt) =>
                          tabNameOutToInHandler(opt)
                        )
                      : []
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputNew
                  id="status_notification_new"
                  label="Status(*)"
                  element="select"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                  clean_options={currentForm && currentForm.operation}
                />
              </Grid>

              <Grid item xs={4}>
                <InputNew
                  id="due_notification_new"
                  label="Due Reference(*)"
                  element="select"
                  validators={[]}
                  onInput={inputHandler}
                  initialValue={
                    currentForm && currentForm["due descriptor"]
                      ? [
                          ...currentForm["due descriptor"].map((i) => i[0]),
                          "NULL",
                        ][0]
                      : ""
                  }
                  onBlur
                  clean_options={
                    currentForm && currentForm["due descriptor"]
                      ? [
                          ...currentForm["due descriptor"].map((i) => i[0]),
                          "NULL",
                        ]
                      : []
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputNew
                  id="due_override_new"
                  label="Due Override"
                  element="select"
                  validators={[]}
                  onInput={inputHandler}
                  initialValue={
                    formState.inputs.due_notification_new?.value === "NULL"
                      ? "NULL"
                      : ""
                  }
                  onBlur
                  clean_options={
                    Array.isArray(currentForm?.["due descriptor"])
                      ? [
                          ...currentForm["due descriptor"].map((i) => i[0]),
                          "NULL",
                        ].filter(
                          (i) =>
                            i !== formState.inputs?.due_notification_new?.value
                        )
                      : []
                  }
                  disabled={
                    (formState.inputs?.due_notification_new
                      ? formState.inputs.due_notification_new?.value
                      : "") === "NULL"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputNew
                  id="day_notification_new"
                  type="number"
                  label="Notification start before due date(days)"
                  element="input"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                  disabled={
                    (formState.inputs.due_notification_new
                      ? formState.inputs.due_notification_new.value
                      : "") === "NULL"
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <InputNew
                  id="during_frequency_new"
                  label="Frequency before due date"
                  element="select"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                  clean_options={frequencyOption}
                  disabled={
                    (formState.inputs.due_notification_new
                      ? formState.inputs.due_notification_new.value
                      : "") === "NULL"
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputNew
                  id="after_frequency_new"
                  label="Frequency after due date"
                  element="select"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                  clean_options={frequencyOption}
                  disabled={
                    (formState.inputs.due_notification_new
                      ? formState.inputs.due_notification_new.value
                      : "") === "NULL"
                  }
                />
              </Grid>

              {/*<Grid item xs={12}>
                <InputNew
                  id="from_notification_new"
                  label="From:"
                  element="input"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                />
                </Grid>*/}
              <Grid item xs={12}>
                <InputNew
                  id="to_notification_new"
                  label="To:"
                  element="autoComplete"
                  validators={[]}
                  onInput={inputHandler}
                  clean_options={groupList}
                  onBlur
                />
              </Grid>
              <Grid item xs={12}>
                <InputNew
                  id="cc_notification_new"
                  label="CC"
                  element="autoComplete"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                  clean_options={groupList}
                />
              </Grid>
              <Grid item xs={12}>
                <InputNew
                  id="subject_notification_new"
                  label="Subject"
                  element="input"
                  validators={[]}
                  initialValue="MRM Model Governance Inventory Tool Reminder"
                  onInput={inputHandler}
                  onBlur
                />
              </Grid>

              <Grid item xs={12}>
                <InputNew
                  id="content_notification_new"
                  label="Email Content"
                  element="textEditor"
                  validators={[]}
                  initialValue='
                    <p><span style="color: rgb(0, 0, 0);">Model Risk Management (MRM)</span>&nbsp;Model Governance Inventory Tool would like to remind the following.<span style="mso-spacerun: yes;">&nbsp; </span></p>
                    <p style="margin: 0in;"><span style="color: #4472c4;">________________________________</span><!-- [if !supportAnnotations]--><!--[endif]--></p>
                    <p style="margin: 0in;"><strong><span style="color: #4472c4; mso-themecolor: accent1;">[Notification]</span></strong></p>
                    <p style="margin: 0in;"><span style="color: #002060;">[MRM Comments]</span></p>
                    <p style="margin: 0in;"><strong><span style="color: #E67E22; font-family: "Helvetica",sans-serif; mso-bidi-font-family: "Times New Roman";">[Notification Status]</span></strong></p>
                    <p style="margin: 0in;"><span style="color: #4472c4;">________________________________</span></p>
                    <p style="margin: 0in;">&nbsp;</p>
                    <p style="margin: 0in;"><span style="color: rgb(0, 0, 0);"><span style="font-family: Helvetica, sans-serif;"><span style="mso-ascii-font-family: Calibri; mso-ascii-theme-font: minor-latin; mso-hansi-font-family: Calibri; mso-hansi-theme-font: minor-latin;">Please do not reply to this email as it was sent from an unmonitored account. Should you need any further information, please feel free to contact MRM yxu@eqbank.ca.</span></span></span></p>
                    <p style="margin: 0in;">&nbsp;</p>
                    <p style="margin: 12.0pt 0in 0in 0in;"><span style="color: rgb(0, 0, 0);">Regards,</span></p>
                    <p style="margin: 0in;"><span style="color: rgb(0, 0, 0);">MRM Model Governance</span></p>
                      '
                  rows={10}
                  onInput={inputHandler}
                  setScrollTop={props.setScrollTop}
                  pageElement={props.pageElement}
                  onBlur
                />
              </Grid>
            </Grid>

            <div className="notification-button">
              <Button
                variant="outlined"
                className="editButton"
                onClick={addNewNotificationHandler}
              >
                Add New Notification
              </Button>
              <Button
                variant="outlined"
                className="editButton"
                onClick={handleClose}
              >
                Exit
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>Notification Configuration</h4>
      </div>
      <hr />
      <div
        className="notification-layout"
        style={{ margin: "2rem 9rem 2rem 9rem" }}
      >
        <div className="notification-option">
          {notification.map((item, i) => (
            <ListItem disablePadding key={i}>
              <ListItemButton
                selected={selectedIndex === `${item.form} -- ${item.operation}`}
                onClick={(event) => {
                  handleSelectionClick(
                    event,
                    `${item.form} -- ${item.operation}`
                  );
                }}
              >
                <ListItemText
                  primary={`${tabNameOutToInHandler(item.form)} -- ${
                    item.operation
                  }`}
                  sx={{ color: item?.due_override !== "" ? "red" : "black" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
        <form className="notification-container">
          <Button
            variant="outlined"
            className="editButton"
            onClick={handleOpen}
          >
            New
          </Button>
          {element}
          <div className="notification-button">
            <Button
              variant="outlined"
              className="editButton"
              onClick={updateNotificationHandler}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className="editButton"
              onClick={deleteNotificationHandler}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Notification;
