import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import Axios from "axios";
import fileDownload from "js-file-download";
import { IconButton, CircularProgress } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { UserContext } from "../../context/user-context";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";

const Download = ({
  code,
  version,
  form,
  validation_id,
  issue_id,
  fileName,
  isIconShow,
  isDownloadNotShow,
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { token } = useContext(UserContext);
  const history = useHistory();

  const handleClick = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };

  const downLoadHandler = async () => {
    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);
    try {
      const response = await sendRequest(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/download/${code}/${version}/${form}/${validation_id ? validation_id : 0}/${issue_id ? issue_id : 0}/${fileName}`,
        "GET",
        null,
        headers
      );
      // window.location.href = data.download
      handleClick(response.download, fileName);
    } catch (error) {
      //console.log(error);
    }
  };

  const previewHandler = async () => {
    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);

    const response = await sendRequest(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/download/${code}/${version}/${form}/${validation_id ? validation_id : 0}/${
        issue_id ? issue_id : 0
      }/${fileName}`,
      "GET",
      null,
      headers
    );

    window.open(response.preview, "_blank");
  };

  // if (isLoading) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    <div style={!isIconShow ? { display: "flex" } : {}}>
      {isIconShow && (
        <IconButton
          color="primary"
          size="small"
          style={{
            color: "#444791",
            textDecoration: "underline",
            fontSize: "1rem",
            textAlign: "left",
          }}
          onClick={downLoadHandler}
        >
          <div style={{ fontSize: "14px", wordBreak: "break-all" }}>
            {fileName}
          </div>
        </IconButton>
      )}
      {!isIconShow && !isDownloadNotShow && (
        <IconButton
          color="primary"
          size="small"
          style={{
            color: "#444791",
            textDecoration: "underline",
            fontSize: "1rem",
            textAlign: "left",
          }}
          onClick={downLoadHandler}
        >
          {!isLoading ? (
            <FileDownloadOutlinedIcon />
          ) : (
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          )}
        </IconButton>
      )}
      {!isIconShow && (
        <IconButton
          color="primary"
          size="small"
          style={{
            color: "#444791",
            textDecoration: "underline",
            fontSize: "1rem",
            textAlign: "left",
          }}
          onClick={previewHandler}
        >
          {!isLoading && <VisibilityIcon />}
        </IconButton>
      )}
    </div>
  );
};

export default Download;
